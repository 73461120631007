import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { StyledCardContainer } from 'v2/pages/internal/ReturnInsights/styles'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { useQuery } from '@tanstack/react-query'

const OrdersToBeShippedGraph = () => {
  const {
    //data: graphData,
    isFetching,
    isError,
  } = useQuery(['', {}], () => {}, {})

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container direction="column" justify="space-between">
          {Array.from({ length: 5 }).map((_, index) => (
            <Grid.Item key={index} xs={12}>
              <Placeholder.Rect height="30px" />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <>
      <ResponsiveContainer className="hc-pb-2x" height={250}>
        <BarChart
          //data={graphData}
          margin={{ top: 11, right: 24 }}
          barSize={20}
          barGap={75}
        >
          <CartesianGrid strokeDasharray="3, 3" />
          <XAxis
            dataKey="return_date"
            dy={7}
            tick={{ 'aria-hidden': true }}
            fontSize={12}
            // tickFormatter={(value: string) =>
            // }
          />
          <YAxis />
          <Tooltip cursor={{ fill: 'transparent' }} />
          <Bar
            name="Return Rate"
            dataKey="return_rate"
            fill="var(--canvas-font-color--interactive-contrast)"
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>

      <Grid.Container className="hc-mt-normal" align="center">
        <StyledCardContainer className="hc-ml-4x hc-pa-dense" />
        <Grid.Item>
          <p className="hc-txt-capitalize">Orders</p>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default OrdersToBeShippedGraph
