import axios from 'axios'

import apiConfig from 'config/apiConfig'

export interface Filter {
  dimension: string
  pattern: string | string[]
  type: string
  display_name?: string
  obj_type?: string
}

export interface TimePeriod {
  calendar_type: string
  granularity?: string
  interval: string
  type: 'relative' | 'absolute'
  compare_by?: {
    type: string
    values: string[]
  }
}

interface Payload {
  filters?: Filter[]
  timePeriod?: TimePeriod
  resultForm?: {
    date: string
  }
}

interface ExecuteCardsType {
  cardId: number
  sellerId: string
  payload: Payload
}

/**
 * Executes a greenfiled API by sending a POST request to the security gateway.
 *
 * @param {Object} params - The parameters for executing the seller card.
 * @param {string} params.cardId - The ID of the card to be executed.
 * @param {string} params.sellerId - The ID of the seller.
 * @param {Object} params.payload - The payload to be sent with the request.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 */
export const postSellerExecuteCard = async ({
  cardId,
  sellerId,
  payload,
}: ExecuteCardsType): Promise<any> => {
  const url = sellerId
    ? `${apiConfig.securityGateway}/sellers/${sellerId}/execute_cards?card_id=${cardId}`
    : `${apiConfig.sellerReports}?card_id=${cardId}`

  const { data } = await axios.post(url, payload)
  return data.result ?? []
}
