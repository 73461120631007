import { FC } from 'react'
import { Card, Grid, Heading, Divider } from '@enterprise-ui/canvas-ui-react'

import {
  StyledItemTypeTableGridContainer,
  StyledItemTypeTableGridItem,
} from '../styles'
import TopItemTypeTable from './TopItemTypeTable'
import ReportDownload from '../ReportDownload'

const TopItemType: FC = () => {
  return (
    <Card className="hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading size={4}>TOP ITEM TYPE</Heading>
          <p className="font-color-secondary hc-fs-xs">
            Top 10 item types surpassing the benchmark
          </p>
        </Grid.Item>
        <Grid.Item>
          <ReportDownload
            returnPerformanceReportTypes={['TOP_ITEM_TYPES']}
            fileName="top-item-type"
            cardName="TOP_ITEM_TYPE"
          />
        </Grid.Item>
      </Grid.Container>
      <Divider className="hc-mt-xs" />
      <StyledItemTypeTableGridContainer>
        <StyledItemTypeTableGridItem xs>
          <TopItemTypeTable />
        </StyledItemTypeTableGridItem>
      </StyledItemTypeTableGridContainer>
    </Card>
  )
}

export default TopItemType
