import HeaderTitle from 'components/common/HeaderTitle'
import PromotionsList from './PromotionsList'
import { trackPageView } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'

export const PromotionsPage = () => {
  trackPageView(FireflyEvent.PROMOTIONS_PAGEVIEWS)

  return (
    <div data-testid="all-promotions">
      <HeaderTitle title={'Promotions'} />

      <PromotionsList />
    </div>
  )
}

export default PromotionsPage
