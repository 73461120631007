import { FC } from 'react'
import { Drawer, List, Heading } from '@enterprise-ui/canvas-ui-react'

interface HelpArticleProps {
  onRequestClose: () => void
  isDrawerVisible: boolean
}

const PromotionsHelpArticle: FC<HelpArticleProps> = ({
  isDrawerVisible,
  onRequestClose,
}) => {
  const articles = [
    {
      title: 'Promotion Experiences on Target.com',
      description:
        "Target elevates certain promotional experiences to highlight savings or to celebrate seasonal moments. Target's planned promotions typically receive more visibility by additional placement on the Home page, Category deal pages, and the Top Deals page.",
      link: '/article/000100136',
    },
    {
      title: 'Promotions and Events Checklist',
      description:
        "Complete these actions before key promotion events so you're ready for Target.com levels of guest demand.",
      link: '/article/000120805',
    },
    {
      title: 'Additional Promotion Guidelines',
      description:
        'Partners can submit promotional or sale pricing on items at any time to capitalize on seasonal moments, traffic, or to be in line with sales running on other sites.',
      link: '/article/000100035',
    },
  ]

  return (
    <>
      <Drawer
        isVisible={isDrawerVisible}
        headingText="About Promotions"
        xs={3}
        onRequestClose={onRequestClose}
      >
        <List size="dense">
          {articles.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              key={index}
              rel="noopener noreferrer"
            >
              <List.Item>
                <Heading size={5}>{item.title}</Heading>
                {item.description}
              </List.Item>
            </a>
          ))}
        </List>
      </Drawer>
    </>
  )
}

export default PromotionsHelpArticle
