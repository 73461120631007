import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Divider, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { Carrier } from 'types/Carrier'
import { Markup } from 'types/Markup'

import useTable from 'components/common/EnhancedTable/useTable'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import CarrierMarkupHistoryTable from '../CarrierMarkupHistoryTable'

import { Direction, PagingParams } from 'services/pageableHelper'
import { getCarrierMarkupHistory } from 'services/sellerShippingManagement'

import { closeDialog } from 'store/dialog/actionCreator'

interface ComponentProps {
  isOpen: boolean
  carrier: Carrier
  handleClose: (newMarkup: Markup) => void
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
}))

type Props = ComponentProps

export const ViewCarrierMarkupHistory = ({ isOpen, carrier }: Props) => {
  const [markupHistory, setMarkupHistory] = useState<Markup[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const dispatch = useDispatch()
  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  const fetchCarrierMarkupHistory = useCallback(
    async ({ direction, orderBy, page, perPage }: PagingParams) => {
      const { data, total } = await getCarrierMarkupHistory(carrier, {
        direction,
        orderBy,
        page,
        perPage,
      })

      setMarkupHistory(data)
      setTotalRows(total)
    },
    [carrier],
  )

  useEffect(() => {
    fetchCarrierMarkupHistory(table.state)
  }, [fetchCarrierMarkupHistory, table.state])

  return (
    <FullScreenDialogContainer
      title="Carrier History"
      onRequestClose={() => dispatch(closeDialog())}
      isOpen={isOpen}
    >
      <Typography
        tabIndex={-1}
        variant="h2"
        color={'textPrimary'}
        pb={1}
        style={{ lineHeight: 1 }}
      >
        {`${carrier === Carrier.FEDEX ? 'FedEx' : carrier} Markup history`}
      </Typography>
      <StyledDivider data-testid="divider-test" color="#333" />
      <Typography
        tabIndex={-1}
        variant="subtitle2"
        color={'textPrimary'}
        style={{ lineHeight: 1, color: '#666' }}
      >
        All carrier markups start/end at 12am CST.
      </Typography>

      <CarrierMarkupHistoryTable
        table={table}
        data={markupHistory as Markup[]}
        isPending={false}
        total={totalRows}
      />
    </FullScreenDialogContainer>
  )
}

export default ViewCarrierMarkupHistory
