import { Chip } from '@enterprise-ui/canvas-ui-react'
import { getPromotionStatus } from 'v2/utils/helper'

const PromotionStatusChipOnDates = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  const status = getPromotionStatus(startDate, endDate)

  if (!status) {
    return <></>
  }

  if (status === 'COMPLETED') {
    return (
      <Chip className="hc-fs-xs">
        <strong>Completed</strong>
      </Chip>
    )
  } else if (status === 'UPCOMING') {
    return (
      <Chip className="hc-fs-xs" color="alert">
        <strong>Upcoming</strong>
      </Chip>
    )
  } else {
    return (
      <Chip className="hc-pl-md hc-pr-md hc-fs-xs" color="success">
        <strong>Live</strong>
      </Chip>
    )
  }
}

export default PromotionStatusChipOnDates
