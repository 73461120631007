import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { toTitleCase } from 'v2/utils/helper'
import { postSellerExecuteCard } from 'v2/services'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'

export type GreenFieldCategoryAutoCompleteOptionType = {
  id: string
  value: string
  label: string
}

const useGreenFieldCategoryAutoComplete = () => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const [categoryOptions, setCategoryOptions] = useState<
    GreenFieldCategoryAutoCompleteOptionType[]
  >([])
  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState<GreenFieldCategoryAutoCompleteOptionType | null>(null)

  useEffect(() => {
    const config = {
      sellerId,
      cardId: GREEN_FIELD_CARD_ID.CATEGORY_FILTER,
      payload: {
        filters: [
          ...(vmmId
            ? [
                {
                  dimension: 'vendor_id',
                  pattern: String(vmmId),
                  type: 'in',
                },
              ]
            : []),
        ],
      },
    }
    postSellerExecuteCard(config).then(
      (response: { category_id: string; category_name: string }[]) => {
        const divisions = response?.map(({ category_id, category_name }) => ({
          id: category_id,
          value: category_id,
          label: toTitleCase(category_name),
        }))
        setCategoryOptions(divisions)
      },
    )
  }, [sellerId, vmmId])

  const onCategoryUpdate = (
    _id: string,
    value: GreenFieldCategoryAutoCompleteOptionType,
  ) => {
    setSelectedCategoryOption(value)
  }

  return {
    selectedCategoryOption,
    categoryOptions,
    onCategoryUpdate,
  }
}

export default useGreenFieldCategoryAutoComplete
