import { Button, Grid, Input } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'
import { useAsyncSellerSelect } from 'v2/hooks/autoComplete'
import { StyledDatePicker } from '../../ReturnInsights/styles'
import { orderStatusLabels } from 'services/enumerations'
import { OrderStatus } from 'types/Orders'
import { getEnumerationValues } from 'store/selectors'
import { EnumerationName } from 'types/Enumeration'
import { useSelector } from 'react-redux'
import StoreState from 'types/state'
import { StyledFilterContainer } from '../styles'
import { useOrdersDashboardStore } from 'v2/store'
import ShipmentHistory from './ShipmentHistory'
import { useMemo } from 'react'

const Filter = () => {
  const { renderComponent } = useAsyncSellerSelect()
  const {
    deliverByDate,
    orderPlacedDate,
    orderStatuses,
    requestShipDate,
    ordersAtRisk,
    updateOrderStatuses,
    updateDeliverByDate,
    updateOrderPlacedDate,
    updateRequestShipDate,
    updateOrdersAtRisk,
  } = useOrdersDashboardStore()

  const statuses = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.ORDER_STATUS),
  )

  const orderStatusesOptions = useMemo(() => {
    return statuses
      .map((stat) => ({
        id: stat,
        label: orderStatusLabels[stat as keyof typeof OrderStatus],
        value: stat,
      }))
      .filter(
        (status) =>
          status.value !== OrderStatus.PENDING &&
          status.value !== OrderStatus.REFUNDED,
      )
  }, [statuses])

  return (
    <StyledFilterContainer className="hc-bg-grey07 hc-pa-normal">
      <Grid.Container direction="column" justify="space-between">
        <Grid.Item>
          <Grid.Container align="top" justify="space-between">
            <Grid.Item>
              <p className="hc-fs-md">Showing Details For :</p>
            </Grid.Item>
            <Grid.Item>
              <ShipmentHistory />
              <Button type="ghost">
                <EnterpriseIcon className="hc-pr-min" icon={DownloadIcon} />
                Download
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container align="baseline" justify="space-between">
            <Grid.Item xs={2}>
              <label>Partner</label>
              {renderComponent({ placeholder: 'Select Partner' })}
            </Grid.Item>
            <Grid.Item xs={2}>
              <Autocomplete
                multiselect
                label="Order Status"
                value={orderStatuses}
                placeholder="Select Order Status"
                options={orderStatusesOptions}
                onUpdate={(_, value) => updateOrderStatuses(value)}
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Order Placed Date"
                placeholder="Select Date"
                onUpdate={(_, value: any) => updateOrderPlacedDate(value)}
                value={orderPlacedDate}
                noValidate
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Deliver By"
                placeholder="Select Date"
                onUpdate={(_, value: any) => updateDeliverByDate(value)}
                value={deliverByDate}
                noValidate
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Request Ship Date"
                placeholder="Select Date"
                onUpdate={(_, value: any) => updateRequestShipDate(value)}
                noValidate
                value={requestShipDate}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>
          <Input.Checkbox
            id="demo_00"
            label="Only show Orders at Risk"
            checked={ordersAtRisk}
            onChange={(event: any) => {
              updateOrdersAtRisk(event.target.checked)
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </StyledFilterContainer>
  )
}

export default Filter
