import { FC, useState, useEffect } from 'react'
import { Button, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { format, isAfter } from 'date-fns'
import EnterpriseIcon, {
  ChartBarHorizontalIcon,
  MaximizeIcon,
} from '@enterprise-ui/icons'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { useReturnInsightsStore } from 'v2/store'
import { datePattern } from 'v2/constant/date'
import { getDifferenceInDays, getDateAddedByDays } from 'v2/utils/date'
import ReportDownload from '../ReportDownload'
import ReturnRateGraph from './ReturnRateGraph'
import { ReturnInformation } from './ReturnInformation'

import {
  StyledReturnRateCardContainer,
  StyledCompareReturnRateCardGridContainer,
  StyledDivider,
} from '../styles'
import ReturnRateModal from './ReturnRateModal'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import { useSelector } from 'react-redux'
import { isRoleExternalUserSelector } from 'store/selectors'

export enum ViewType {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

type TimeFrame = {
  startDate: string
  endDate: string
}

const getReturnType = (viewType: ViewType) => {
  const returnTypeData = {
    [ViewType.MONTH]: 'RETURN_RATE_MONTH',
    [ViewType.WEEK]: 'RETURN_RATE_WEEK',
    [ViewType.DAY]: 'RETURN_RATE',
  }

  return [returnTypeData[viewType]]
}

const ReturnRate: FC = () => {
  const [cardId, setCardId] = useState(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  const [view, setView] = useState<ViewType>(ViewType.DAY)
  const [datePickerError, setDatePickerError] = useState('')
  const [timeFrame, updateTimeFrame] = useState<TimeFrame | null>(null)
  const [isMaximized, setIsMaximized] = useState(false)
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  const {
    timeFrame: primaryTimeFrame,
    isCompareViewEnabled,
    updateCompareView,
    updateCompareTimeFrame,
    clearCompareInterval,
    compareDatePickerDisableDate,
  } = useReturnInsightsStore()

  useEffect(() => {
    if (isCompareViewEnabled) {
      clearCompareState()
    }
  }, [isCompareViewEnabled])

  useEffect(() => {
    if (view === ViewType.MONTH)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_MONTH)
    else if (view === ViewType.WEEK)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_WEEK)
    else if (view === ViewType.DAY)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  }, [view])

  const onDatePickerUpdate = (_id: string, value: string) => {
    if (value) {
      const numberOfDays = getDifferenceInDays(
        primaryTimeFrame?.startDate!,
        primaryTimeFrame?.endDate!,
      )

      updateTimeFrame({
        startDate: value,
        endDate: format(
          getDateAddedByDays(new Date(value), numberOfDays),
          datePattern.slash.MM_dd_yyyy,
        ),
      })
    } else {
      updateTimeFrame(null)
    }
    setDatePickerError('')

    logFireflyEvent(FireflyEvent.RETURN_INSIGHTS_COMPARE_BUTTON_CLICK)
  }

  const onApplyClick = () => {
    if (
      timeFrame?.startDate === primaryTimeFrame?.startDate &&
      timeFrame?.endDate === primaryTimeFrame?.endDate
    ) {
      setDatePickerError(
        'Comparison period cannot be same as Primary period. Please select another period.',
      )
    } else if (
      timeFrame?.endDate &&
      isAfter(
        new Date(timeFrame.endDate),
        new Date(compareDatePickerDisableDate),
      )
    ) {
      setDatePickerError(
        'The return data cannot be accessed for recent 30 days. Please select another comparison period.',
      )
    } else {
      updateCompareTimeFrame(timeFrame!)
      clearCompareState()
      updateCompareView(false)
    }

    logFireflyEvent(FireflyEvent.RETURN_INSIGHTS_COMPARE_APPLY_BUTTON_CLICK)
  }

  const clearCompareState = () => {
    updateTimeFrame(null)
    setDatePickerError('')
  }

  const onClickClose = () => {
    updateCompareView(!isCompareViewEnabled)
    clearCompareState()
  }
  const logFireflyEvent = (event: FireflyEvent, view?: ViewType) => {
    if (isExternalUser) {
      trackCustomEvent(event, event.toString(), view ?? '')
    }
  }

  const handleViewClick = (view: ViewType) => {
    setView(view)
    clearCompareInterval()
    logFireflyEvent(
      FireflyEvent.RETURN_INSIGHTS_RETURN_RATE_TIME_AXIS_SELECTION,
      view,
    )
  }

  return (
    <StyledReturnRateCardContainer className="hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item xs={6}>
          <Heading size={4}>RETURN RATE</Heading>
          <p className="font-color-secondary hc-fs-xs">
            % return sales for the orders that were placed in the selected time
            period
          </p>
        </Grid.Item>
        {!isCompareViewEnabled && (
          <Grid.Item>
            <Grid.Container align="center" justify="flex-end">
              <Grid.Item>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  disabled={view !== ViewType.DAY}
                  onClick={() => updateCompareView(!isCompareViewEnabled)}
                >
                  <EnterpriseIcon icon={ChartBarHorizontalIcon} size="sm" />
                  Compare
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  variant="contained"
                  onClick={() => setIsMaximized(true)}
                >
                  <EnterpriseIcon icon={MaximizeIcon} size="sm" />
                </Button>
              </Grid.Item>
              <Grid.Item>
                <ReportDownload
                  returnPerformanceReportTypes={getReturnType(view)}
                  fileName="return-rate"
                  cardLevel={view}
                  cardName={'RETURN_RATE'}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}
      </Grid.Container>
      <StyledDivider className="hc-mt-xs" />
      {isMaximized && (
        <ReturnRateModal
          isMaximized={isMaximized}
          setIsMaximized={setIsMaximized}
          cardId={cardId}
          view={view}
          handleViewClick={handleViewClick}
        />
      )}
      {isCompareViewEnabled ? (
        <StyledCompareReturnRateCardGridContainer
          className="hc-pa-normal"
          justify="center"
          align="center"
        >
          <Grid.Item xs={10}>
            <DatePicker
              id="return-insights-compare-date-picker"
              label="Compare with"
              error={!!datePickerError}
              errorText={datePickerError}
              onUpdate={onDatePickerUpdate}
              value={timeFrame?.startDate}
              hintText="Returns data cannot be accessed for recent 30 days"
              disableDates={{ after: compareDatePickerDisableDate }}
            />
            <Grid.Container className="hc-mt-normal" justify="flex-end">
              <Grid.Item>
                <Button
                  type="secondary"
                  size="normal"
                  variant="contained"
                  onClick={onClickClose}
                >
                  Close
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  size="normal"
                  variant="contained"
                  onClick={onApplyClick}
                  disabled={timeFrame === null}
                >
                  Apply
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </StyledCompareReturnRateCardGridContainer>
      ) : (
        <Grid.Container className="hc-pa-normal">
          <Grid.Item xs={3}>
            <ReturnInformation />
          </Grid.Item>
          <Grid.Item xs={9}>
            <ReturnRateGraph
              cardId={cardId}
              view={view}
              handleViewClick={handleViewClick}
            />
          </Grid.Item>
        </Grid.Container>
      )}
    </StyledReturnRateCardContainer>
  )
}

export default ReturnRate
