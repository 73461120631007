import { useState, useEffect } from 'react'

import styled from '@emotion/styled'

import Text from 'components/common/Text'

import {
  BillOfLadingTypeahead,
  CaseTypeahead,
  DivisionTypeahead,
  HeliosItemTypeahead,
  ItemTypeahead,
  LicensePlateTypeahead,
  ProductReturnTypeahead,
  PromotionTypeahead,
  SellerTypeahead,
  ShipmentIdTypeahead,
  StoreNumberTypeahead,
  TaxonomyTypeahead,
  TrackingNumberTypeahead,
} from '../Typeahead'
import StoreReferenceFieldTypeahead from '../Typeahead/StoreReferenceFieldTypeahead'

import { getCaseById, getCases } from 'services/cases'
import { getNodeById } from 'services/itemTaxonomies'
import { getMarketplaceProductByTcin } from 'services/items'
import { getSeller, searchSellers } from 'services/seller'
import { getHeliosItemList } from 'services/helios'
import { searchProductReturns } from 'services/productReturns'
import { getPromotionById } from 'services/promotions'
import { getPromotionById as getSellerPromotionById } from 'v2/services/promotions'
import { Direction } from 'services/pageableHelper'
import { getDivisions } from 'services/itemGroups'
import { fetchReturnDisputes } from 'services/returnDisputes'
import { getReturnsResearch } from 'services/sellerReturns'

import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { Case } from 'types/Case'
import {
  isAttribute,
  isCase,
  isDivision,
  isHeliosItem,
  isMarketplaceProduct,
  isPromotion,
  isReturn,
  isSmsSeller,
  isStore,
  isReturnsResearch,
} from 'types/Guards'
import { SmsSeller } from 'types/Seller'
import { MarketplaceProduct } from 'types/Item'
import { HeliosListItem } from 'types/HeliosItem'
import { Return, ReturnsResearch } from 'types/Orders'
import { Store } from 'types/Store'
import { UpdatedPromotion } from 'types/Promotion'
import { Division } from 'types/Division'
import { Typography } from '@mui/material'
import { error } from 'config/themeConfig'

const StyledLabel = styled(Text)((props) => ({
  marginBottom: props.theme.spacing(1),
}))

export type Props = {
  value: string | undefined
  searchParam: keyof Dictionary<string | undefined>
  status?: string
  caseType?: string
  sellerId?: string
  label?: string
  placeholder?: string
  paramsToClearOnChange?: string[]
  onChange: (params: Dictionary<string | undefined>) => void
  returnsResearch?: boolean
}

export const TypeaheadFilter = ({
  value,
  searchParam,
  status,
  caseType,
  sellerId,
  label,
  placeholder,
  paramsToClearOnChange,
  onChange,
  returnsResearch,
}: Props) => {
  const [selectedResult, setSelectedResult] =
    useState<
      Nullable<
        | SmsSeller
        | Attribute
        | MarketplaceProduct
        | HeliosListItem
        | Return
        | Store
        | Case
        | UpdatedPromotion
        | Division
        | ReturnsResearch
      >
    >()

  useEffect(() => {
    const getSellerName = async (id: string) => {
      const seller = await getSeller(id)
      setSelectedResult(seller)
    }

    const getSellerNameByVmmId = async (vmmId: string) => {
      const seller = await searchSellers(
        { page: 0, perPage: 1 },
        { vmm_id: vmmId },
      )
      setSelectedResult(seller.data[0])
    }

    const getNodeName = async (id: string) => {
      const attribute = await getNodeById(id)
      setSelectedResult(attribute)
    }

    const getProduct = async (id: string) => {
      const product = await getMarketplaceProductByTcin(id)
      setSelectedResult(product)
    }

    const getHeliosItemByTcin = async (tcin: string, status: string) => {
      const item = await getHeliosItemList(
        { tcin, status },
        {
          page: 0,
          pageSize: 1,
          order: Direction.ASC,
          sort: 'source_timestamp',
        },
      )
      setSelectedResult(item?.data?.[0])
    }

    const getReturn = async (query: string, sellerId: string | undefined) => {
      const returnItem = await searchProductReturns(
        {
          page: 0,
          perPage: 1,
          direction: Direction.ASC,
          orderBy: 'return_date',
        },
        { seller_id: sellerId, [searchParam]: query },
      )
      setSelectedResult(returnItem?.data?.[0])
    }

    const getStore = async (query: string) => {
      const stores = await Promise.resolve([
        {
          location_id: parseInt(query),
        },
      ] as Store[])

      setSelectedResult(stores[0])
    }

    const getCase = async (
      query: string,
      queryType: string,
      sellerId?: string,
      caseType?: string,
    ) => {
      let caseItem
      if (queryType === 'case_number') {
        caseItem = await getCases({
          seller_id: sellerId,
          case_number: query,
          caseType,
          page: 0,
          perPage: 1,
        })
        setSelectedResult(caseItem.data[0])
      } else if (queryType === 'case_id') {
        if (sellerId) {
          caseItem = await getCaseById({ sellerId: sellerId, caseId: query })
          setSelectedResult(caseItem)
        } else {
          caseItem = await fetchReturnDisputes(
            {
              source: 'RETURN_DISPUTE',
              case_id: query,
            },
            { page: 0, perPage: 1 },
          )

          setSelectedResult(caseItem.data[0])
        }
      }
    }

    const getDivision = async (id: string) => {
      const result = await getDivisions()
      const division = result.find(
        (division: Division) => division.division_id === parseInt(id),
      )

      setSelectedResult(division)
    }

    const getPromotion = async (id: string) => {
      let promotion
      if (sellerId) {
        promotion = await getSellerPromotionById(
          {
            params: {
              promotion_id: id,
            },
          },
          sellerId,
        )
      } else {
        promotion = await getPromotionById(id)
      }

      setSelectedResult(promotion[0])
    }

    const getReturnResearch = async (query: string) => {
      const { data } = await getReturnsResearch(
        {
          page: 0,
          perPage: 1,
        },
        { [searchParam]: query },
      )

      setSelectedResult(data[0])
    }

    if (value) {
      if (searchParam === 'seller_id') {
        getSellerName(value)
      } else if (searchParam === 'vendor_id') {
        getSellerNameByVmmId(value)
      } else if (searchParam === 'item_type_id') {
        getNodeName(value)
      } else if (searchParam === 'tcin') {
        getProduct(value)
      } else if (searchParam === 'helios_tcin' && status) {
        getHeliosItemByTcin(value, status)
      } else if (
        searchParam === 'license_plate' ||
        searchParam === 'tracking_number' ||
        searchParam === 'return_item' ||
        searchParam === 'bill_of_lading' ||
        searchParam === 'return_order_number' ||
        searchParam === 'shipment_id'
      ) {
        returnsResearch ? getReturnResearch(value) : getReturn(value, sellerId)
      } else if (
        searchParam === 'location_id' ||
        searchParam === 'store_number'
      ) {
        getStore(value)
      } else if (searchParam === 'case_number' || searchParam === 'case_id') {
        getCase(value, searchParam, sellerId, caseType)
      } else if (searchParam === 'promotion_id') {
        getPromotion(value)
      } else if (searchParam === 'division_id') {
        getDivision(value)
      } else if (searchParam === 'store_reference_field') {
        getReturnResearch(value)
      }
    } else {
      setSelectedResult(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (
    typeaheadValue: Nullable<
      Partial<
        | SmsSeller
        | Attribute
        | MarketplaceProduct
        | HeliosListItem
        | Return
        | Store
        | Case
        | UpdatedPromotion
        | Division
        | ReturnsResearch
      >
    >,
    enteredValue?: string,
  ) => {
    if (!typeaheadValue) {
      return
    }

    let paramsToClear = {}
    if (Array.isArray(paramsToClearOnChange)) {
      paramsToClear = paramsToClearOnChange.reduce((prev, curr) => {
        prev[curr] = undefined

        return prev
      }, {} as any)
    }

    if (searchParam === 'seller_id' && isSmsSeller(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.id })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'vendor_id' && isSmsSeller(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.vmm_id })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'item_type_id' && isAttribute(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.id })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'tcin' && isMarketplaceProduct(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.tcin })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'helios_tcin' && isHeliosItem(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.tcin })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'license_plate' && isReturn(typeaheadValue)) {
      const trackingInfo = typeaheadValue.tracking_data?.find(
        (trackingItem) => {
          if (trackingItem.license_plate) {
            return trackingItem.license_plate
              .toString()
              .startsWith(enteredValue!)
          }

          return false
        },
      )
      onChange({
        ...paramsToClear,
        [searchParam]: trackingInfo?.license_plate?.toString(),
      })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'tracking_number' && isReturn(typeaheadValue)) {
      const trackingInfo = typeaheadValue.tracking_data?.find(
        (trackingItem) => {
          if (trackingItem.tracking_number) {
            return trackingItem.tracking_number
              .toString()
              .startsWith(enteredValue!)
          }

          return false
        },
      )
      onChange({
        ...paramsToClear,
        [searchParam]: trackingInfo?.tracking_number?.toString() ?? '',
      })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'bill_of_lading' && isReturn(typeaheadValue)) {
      const trackingInfo = typeaheadValue.tracking_data?.find(
        (trackingItem) => {
          if (trackingItem.bill_of_lading) {
            return trackingItem.bill_of_lading
              .toString()
              .startsWith(enteredValue!)
          }

          return false
        },
      )
      if (trackingInfo?.bill_of_lading) {
        onChange({
          ...paramsToClear,
          [searchParam]: trackingInfo.bill_of_lading.toString(),
        })
      }
      setSelectedResult(typeaheadValue)
    } else if (
      searchParam === 'return_order_number' &&
      isReturn(typeaheadValue)
    ) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.return_order_number.toString(),
      })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'shipment_id' && isReturn(typeaheadValue)) {
      const trackingInfo = typeaheadValue.tracking_data?.find(
        (trackingItem) => {
          if (trackingItem.shipment_id) {
            return trackingItem.shipment_id.toString().startsWith(enteredValue!)
          }

          return false
        },
      )
      onChange({
        ...paramsToClear,
        [searchParam]: trackingInfo?.shipment_id?.toString(),
      })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'return_item' && isReturn(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.tcin })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'case_number' && isCase(typeaheadValue)) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.case_number })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'case_id' && isCase(typeaheadValue)) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.case_id,
      })
      setSelectedResult(typeaheadValue)
    } else if (
      (searchParam === 'location_id' || searchParam === 'store_number') &&
      isStore(typeaheadValue)
    ) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.location_id.toString(),
      })
      setSelectedResult(typeaheadValue)
    } else if (searchParam === 'promotion_id' && isPromotion(typeaheadValue)) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.promotion_id?.toString(),
      })
    } else if (searchParam === 'division_id' && isDivision(typeaheadValue)) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.division_id.toString(),
      })
      setSelectedResult(typeaheadValue)
    } else if (
      searchParam === 'store_reference_field' &&
      isReturnsResearch(typeaheadValue)
    ) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.store_reference_field,
      })
      setSelectedResult(typeaheadValue)
    } else if (
      searchParam === 'pro_number' &&
      isReturnsResearch(typeaheadValue)
    ) {
      onChange({ ...paramsToClear, [searchParam]: typeaheadValue.pro_number })
      setSelectedResult(typeaheadValue)
    } else if (
      searchParam === 'license_plate' &&
      isReturnsResearch(typeaheadValue)
    ) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.license_plate,
      })
      setSelectedResult(typeaheadValue)
    } else if (
      searchParam === 'shipment_id' &&
      isReturnsResearch(typeaheadValue)
    ) {
      onChange({
        ...paramsToClear,
        [searchParam]: typeaheadValue.shipment_id,
      })
      setSelectedResult(typeaheadValue)
    }
  }

  const handleOnClear = () => {
    onChange({ [searchParam]: undefined })
    setSelectedResult(undefined)
  }

  const getComponent = (): JSX.Element | undefined => {
    if (searchParam === 'seller_id' || searchParam === 'vendor_id') {
      return (
        <SellerTypeahead
          onChange={handleChange}
          value={isSmsSeller(selectedResult) ? selectedResult : null}
          placeholder={placeholder}
          onClear={handleOnClear}
          hasVmmIdSearch={!sellerId}
        />
      )
    } else if (searchParam === 'tcin') {
      return (
        <ItemTypeahead
          sellerId={sellerId}
          placeholder={placeholder ?? 'TCIN, SKU, Barcode'}
          onChange={handleChange}
          value={isMarketplaceProduct(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'item_type_id') {
      return (
        <TaxonomyTypeahead
          placeholder={placeholder ?? 'Search Item Type'}
          onChange={handleChange}
          searchType={ITEM_TYPE}
          value={isAttribute(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'helios_tcin' && status) {
      return (
        <HeliosItemTypeahead
          placeholder={placeholder ?? 'Search TCIN'}
          status={status}
          onChange={handleChange}
          value={isHeliosItem(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'license_plate' && !returnsResearch) {
      return (
        <LicensePlateTypeahead
          onChange={handleChange}
          value={isReturn(selectedResult) ? selectedResult : null}
          sellerId={sellerId}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'license_plate' && returnsResearch) {
      return (
        <LicensePlateTypeahead
          onChange={handleChange}
          value={isReturnsResearch(selectedResult) ? selectedResult : null}
          sellerId={sellerId}
          onClear={handleOnClear}
          returnsResearch
        />
      )
    } else if (searchParam === 'tracking_number') {
      return (
        <TrackingNumberTypeahead
          onChange={handleChange}
          value={isReturn(selectedResult) ? selectedResult : null}
          sellerId={sellerId}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'bill_of_lading') {
      return (
        <BillOfLadingTypeahead
          onChange={handleChange}
          value={isReturn(selectedResult) ? selectedResult : null}
          sellerId={sellerId}
          onClear={handleOnClear}
        />
      )
    } else if (
      searchParam === 'location_id' ||
      searchParam === 'store_number'
    ) {
      return (
        <StoreNumberTypeahead
          onChange={handleChange}
          value={isStore(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'return_order_number') {
      return (
        <ProductReturnTypeahead
          placeholder={placeholder ?? 'Return Order Number'}
          sellerId={sellerId!}
          onChange={handleChange}
          value={isReturn(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
          perPage={1}
        />
      )
    } else if (searchParam === 'shipment_id' && !returnsResearch) {
      return (
        <ShipmentIdTypeahead
          placeholder={placeholder ?? 'Shipment ID'}
          sellerId={sellerId!}
          onChange={handleChange}
          value={isReturn(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
          perPage={1}
        />
      )
    } else if (searchParam === 'shipment_id' && returnsResearch) {
      return (
        <ShipmentIdTypeahead
          placeholder={placeholder ?? 'Shipment ID'}
          onChange={handleChange}
          value={isReturnsResearch(selectedResult) ? selectedResult : null}
          sellerId={sellerId}
          onClear={handleOnClear}
          returnsResearch
        />
      )
    } else if (searchParam === 'case_number' || searchParam === 'case_id') {
      return (
        <CaseTypeahead
          placeholder={placeholder}
          sellerId={sellerId}
          caseType={caseType}
          onChange={handleChange}
          value={isCase(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'promotion_id') {
      return (
        <PromotionTypeahead
          placeholder={placeholder}
          onChange={handleChange}
          value={isPromotion(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
          sellerId={sellerId}
          label={label}
        />
      )
    } else if (searchParam === 'division_id') {
      return (
        <DivisionTypeahead
          placeholder={placeholder}
          onChange={handleChange}
          value={isDivision(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'store_reference_field') {
      return (
        <StoreReferenceFieldTypeahead
          placeholder={placeholder}
          onChange={handleChange}
          value={isReturnsResearch(selectedResult) ? selectedResult : null}
          onClear={handleOnClear}
        />
      )
    } else if (searchParam === 'pro_number') {
      return (
        <TrackingNumberTypeahead
          onChange={handleChange}
          value={isReturnsResearch(selectedResult) ? selectedResult : null}
          sellerId={sellerId}
          onClear={handleOnClear}
          returnsResearch
        />
      )
    }
  }

  const TypeaheadComponent = (
    <>
      {label && <StyledLabel type="bold">{label}</StyledLabel>}
      {getComponent()}
      {value && !selectedResult && (
        <Typography color={error.main}>Invalid {label}</Typography>
      )}
    </>
  )

  return TypeaheadComponent
}

export default TypeaheadFilter
