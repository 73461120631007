import { Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ShipIcon } from '@enterprise-ui/icons'
import { useState } from 'react'

const ShipmentHistory = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openShipmentModal = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      {isModalOpen && (
        <Modal
          isVisible={isModalOpen}
          onRefuse={() => setIsModalOpen(false)}
          headingText={'Download Shipment History'}
          size="dense"
        >
          <div className="hc-pa-normal">
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item xs>
                <p>
                  Shipment History data can help to keep a track of the detailed
                  report of all shipments that has happened over the selected
                  period of time.
                </p>
                <p>Do you wish to download the data?</p>
              </Grid.Item>
            </Grid.Container>
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item>
                <Button onClick={() => setIsModalOpen(false)} type="primary">
                  Download
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button onClick={() => setIsModalOpen(false)} type="secondary">
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
      <Button type="secondary" onClick={openShipmentModal}>
        <EnterpriseIcon className="hc-pr-min" icon={ShipIcon} />
        Shipment History
      </Button>
    </>
  )
}

export default ShipmentHistory
