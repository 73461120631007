import { Cell, Pie, PieChart } from 'recharts'

const COLORS = ['#BC497B', '#3CB9B5', '#2758B9']

// TODO: remove after api integration
const dummyData = [
  {
    name: 'upto 1 Day',
    value: 1500,
  },
  {
    name: '2 to 7 Days',
    value: 900,
  },
  {
    name: '8 Days or More',
    value: 4000,
  },
]

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + 100
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      <tspan x={x} dy="0" fontSize={14}>
        {`${dummyData[index].name}`}
      </tspan>
      <tspan
        x={x}
        dy="1em"
        fontSize={24}
        fill="var(--canvas-background-color--interactive"
      >
        {`${dummyData[index].value}`}
      </tspan>
    </text>
  )
}

const UnshippedPastDueGraphByAge = () => {
  return (
    <PieChart width={400} height={400}>
      <Pie
        data={dummyData}
        cx={180}
        cy={200}
        outerRadius={110}
        fill="#8884d8"
        dataKey="value"
        label={renderCustomizedLabel}
      >
        {dummyData.map((_, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  )
}

export default UnshippedPastDueGraphByAge
