import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'
import { FC } from 'react'

interface ReportDownloadProps {
  fileName: string
  returnPerformanceReportTypes: string[]
  itemTypeIds?: string[]
}

const ReportDownload: FC<ReportDownloadProps> = ({ fileName }) => {
  return (
    <>
      <Button
        className="hc-pl-xs hc-pr-xs"
        type="secondary"
        onClick={() => {}}
        aria-label={`Download report for ${fileName}`}
      >
        <EnterpriseIcon size="md" icon={DownloadIcon} />
      </Button>
    </>
  )
}

export default ReportDownload
