import React, { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DialogEnum } from 'components/common/Dialog'
import useTable from 'components/common/EnhancedTable/useTable'
import Link from 'components/common/Link'
import TableSpacer from 'components/common/TableSpacer'

import ReferralFeeTable from './ReferralFeeTable'

import { isOneOfUserRoles } from 'services/authorization'
import { Direction, PagingParams } from 'services/pageableHelper'
import { getReferralPercentages } from 'services/referralFees'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
} from 'services/roles'
import { RoutePath } from 'services/NavigationHelper'
import {
  getMemberOf,
  currentSellerId,
  isDefunctSellerStatus,
} from 'store/selectors'

import { ReferralPercentage } from 'types/ReferralFee'
import { Button, ButtonProps, Grid } from '@enterprise-ui/canvas-ui-react'
import { TaxonomyTypeahead } from 'components/common/Typeahead'
import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { PriceRangePayload } from 'components/ReferralFeesV2/types'
import { EditReferralFeesDrawer } from 'components/ReferralFeesV2/EditReferralFeesDrawer'
import { StyledText } from 'components/BaseReferralFeesPage/styles'
import { openDialog } from 'store/dialog/actionCreator'

const canShowRoles = [USER_ROLE_ADMIN, USER_ROLE_OPS, USER_ROLE_PROGRAM_MANAGER]

export const ReferralFeeContent = () => {
  const sellerId = useSelector(currentSellerId)
  const memberOf = useSelector(getMemberOf)
  const reduxDispatch = useDispatch()
  const [pending, setPending] = React.useState(false)
  const [referralFees, setReferralFees] = React.useState<ReferralPercentage[]>(
    [],
  )
  const [itemTypeId, setItemTypeId] = useState('')
  const [isEditFlyoutOpen, setIsEditFlyoutOpen] = useState(false)
  const [title, setTitle] = useState('')

  const [percent, setPercent] = useState<Nullable<string>>(null)
  const [attribute, setAttribute] = useState<Nullable<Attribute>>(null)
  const [priceRangeRows, setPriceRangeRows] = useState<PriceRangePayload[]>([])

  const [total, setTotal] = React.useState(0)

  const isDefunct = useSelector(isDefunctSellerStatus)

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  const handleCloseEditFlyout = () => {
    setIsEditFlyoutOpen(false)
    setPercent('')
    setAttribute(null)
    setPriceRangeRows([])
  }

  const handleFormChange =
    ({ type }: { type: 'attribute' | 'percent' }) =>
    (value: Nullable<Attribute | Nullable<string>>) => {
      if (type === 'attribute') {
        setAttribute(value as Nullable<Attribute>)
      } else if (type === 'percent') {
        setPercent(value as Nullable<string>)
      }
    }

  const handleReferralFeeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPercent(e.target.value)
  }

  React.useEffect(() => {
    if (sellerId && !isEditFlyoutOpen) {
      const fetchReferralFees = async ({
        direction,
        orderBy,
        page,
        perPage,
      }: PagingParams) => {
        setPending(true)
        const { data, total: totalCount } = await getReferralPercentages(
          sellerId,
          {
            direction,
            orderBy,
            page,
            perPage,
          },
          {
            item_type_id: itemTypeId,
            latest: true,
          },
        )
        setReferralFees(data)
        setTotal(totalCount)
        setPending(false)
      }

      fetchReferralFees(table.state)
    }
  }, [sellerId, table.state, itemTypeId, isEditFlyoutOpen])

  const hide = !isOneOfUserRoles(memberOf, canShowRoles)

  const handleItemTypeChange = (value: any) => {
    if (!value) return
    setItemTypeId(value.id)
  }

  const handleFilterClear = () => {
    setItemTypeId('')
  }

  const handleOnEditClick = (title: string, rowItem?: ReferralPercentage) => {
    setIsEditFlyoutOpen(true)
    setTitle(title)
    if (rowItem) {
      setPercent((rowItem.referral_percentage * 100).toFixed())
      const attribute = {
        id: rowItem.item_type_id,
        name: rowItem.itemTypeName,
      }
      setAttribute(attribute)
      if (rowItem.price_range_referral_percentages) {
        const priceRangeRowsCopy = rowItem.price_range_referral_percentages
        priceRangeRowsCopy.forEach((item) => {
          item.referral_percentage =
            item.referral_percentage &&
            (Number(item.referral_percentage) * 100).toFixed(2)
        })
        setPriceRangeRows(priceRangeRowsCopy)
      }
    }
  }

  const AddNewReferralFee = ({ children }: ButtonProps) => (
    <Button
      children={children}
      key="add-new-base-fee-button"
      data-testid="add-new-base-fee-button"
      type="primary"
      size="normal"
      variant="contained"
      onClick={() => handleOnEditClick?.('Add New Referral Fee')}
    />
  )

  const handleViewHistory = (referralPercentage: ReferralPercentage) => () => {
    const { item_type_id, itemTypeName } = referralPercentage
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.PRICE_RANGE_REFERRAL_FEE_HISTORY,
        componentProps: {
          itemTypeId: item_type_id,
          itemTypeName,
          sellerId,
        },
      }),
    )
  }

  return (
    <>
      <StyledText>
        All fees are based on the subtype base referral fees unless a different
        referral fee is specified below. To view a complete list of allowed item
        types and referral fees for this partner,&nbsp;
        <Link to={`/${sellerId}${RoutePath.REPORTS}`}>
          download the allowed item type report
        </Link>
        .
      </StyledText>
      <Grid.Container className="hc-pt-xl" justify="space-between">
        <Grid.Item xs={4} className="hc-pl-sm">
          <TaxonomyTypeahead
            searchType={ITEM_TYPE}
            placeholder="Search Item Type"
            onChange={handleItemTypeChange}
            onClear={handleFilterClear}
          />
        </Grid.Item>
        {isDefunct ||
          (!hide && (
            <Grid.Item className="hc-pr-md hc-pb-md">
              <AddNewReferralFee>ADD NEW REFERRAL FEE</AddNewReferralFee>
            </Grid.Item>
          ))}
      </Grid.Container>
      <TableSpacer>
        <ReferralFeeTable
          table={table}
          data={referralFees}
          isPending={pending}
          total={total}
          canEdit={!hide && !isDefunct}
          handleOnEditClick={handleOnEditClick}
          viewHistory={handleViewHistory}
        />
      </TableSpacer>
      {isEditFlyoutOpen && (
        <EditReferralFeesDrawer
          attribute={attribute}
          percent={percent ?? ''}
          sellerId={sellerId ?? ''}
          title={title}
          isBaseReferralFee={false}
          isEditFlyoutOpen={isEditFlyoutOpen}
          handleCloseEditFlyout={handleCloseEditFlyout}
          onRequestChange={handleFormChange}
          handleReferralFeeChange={handleReferralFeeChange}
          priceRanges={priceRangeRows}
        />
      )}
    </>
  )
}

export default ReferralFeeContent
