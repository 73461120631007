import { Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { StyledCard } from '../styles'

interface SummaryCardProps {
  title: string
  value: string
  tooltipText?: string
}

const SummaryCard = ({ title, tooltipText, value }: SummaryCardProps) => {
  const getCardContent = () => {
    return (
      <StyledCard className="hc-pa-sm">
        <Grid.Item className="font-color-default hc-fs-sm ">{title}</Grid.Item>
        <Grid.Item className="font-color-default hc-fs-lg ">
          <strong>{value}</strong>
        </Grid.Item>
      </StyledCard>
    )
  }
  return (
    <>
      {tooltipText ? (
        <Tooltip content={tooltipText} location="top">
          {getCardContent()}
        </Tooltip>
      ) : (
        getCardContent()
      )}
    </>
  )
}

export default SummaryCard
