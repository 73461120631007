import { useState, useMemo } from 'react'
import { format } from 'date-fns'
import { Button, Card, Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { useDispatch } from 'react-redux'

import { StyledPromotionDetailsHeading } from '../styles'
import { StyledCurrencyInput, StyledForm, StyledFormField } from './styles'
import { Division } from 'types/Division'
import { addPromotion, updatePromotion } from 'services/promotions'
import { showNotification } from 'store/notification/reducer'
import { DATE_DISPLAY_FORMAT } from 'services/dateService'
import { UpdatedPromotion } from 'types/Promotion'
import { DivisionTypeahead } from 'components/common/Typeahead'
import use3pPromotionsStore from 'v2/store/use3pPromotionsStore'
import { getPSTDateAndTime } from 'v2/utils/date'

interface PromotionDetailsFormProps {
  onRequestClose: () => void
  refreshData: () => void
}

const PromotionDetailsForm = ({
  onRequestClose,
  refreshData,
}: PromotionDetailsFormProps) => {
  const dispatch = useDispatch()
  const { selectedPromotion } = use3pPromotionsStore()
  const merch = selectedPromotion?.merchandise_hierarchies?.[0]
  const promoIdAndName = selectedPromotion
    ? `${selectedPromotion?.promotion_id} - ${selectedPromotion?.name}`
    : 'This field will be generated once you saved the promotion.'

  const [promoMessage, setPromoMessage] = useState<string>(
    selectedPromotion?.umbrella_label ?? '',
  )
  const [gmvForecast, setGmvForecast] = useState<number | undefined>(
    selectedPromotion?.gmv,
  )
  const [discountType, setDiscountType] = useState<string>(
    selectedPromotion?.details?.[0]?.discount_type ?? 'DollarOff',
  )
  const [discountValue, setDiscountValue] = useState<number | undefined>(
    selectedPromotion?.details?.[0]?.discount_value,
  )
  const [division, setDivision] = useState<Nullable<Division>>(
    merch
      ? {
          division_id: merch?.division_id ?? 0,
          division_name: merch?.division_name ?? '',
          group_id: merch?.pyramid_id,
          group_name: merch?.pyramid_name,
        }
      : null,
  )

  const [startDate, setStartDate] = useState<Date | string>(
    selectedPromotion?.start_date
      ? format(
          getPSTDateAndTime(selectedPromotion?.start_date ?? ''),
          DATE_DISPLAY_FORMAT,
        )
      : '',
  )
  const [endDate, setEndDate] = useState<Date | string>(
    selectedPromotion?.end_date
      ? format(
          getPSTDateAndTime(selectedPromotion?.end_date ?? ''),
          DATE_DISPLAY_FORMAT,
        )
      : '',
  )

  const [startTime, setStartTime] = useState<string>(
    selectedPromotion?.start_date
      ? format(getPSTDateAndTime(selectedPromotion?.start_date ?? ''), 'HH:mm')
      : '00:00',
  )
  const [endTime, setEndTime] = useState<string>(
    selectedPromotion?.start_date
      ? format(getPSTDateAndTime(selectedPromotion?.end_date ?? ''), 'HH:mm')
      : '23:59',
  )

  const handleDivisionChange = (value: Nullable<Division>) => {
    setDivision(value)
  }

  const isFormValid = useMemo(() => {
    return (
      !promoMessage ||
      !gmvForecast ||
      !discountValue ||
      (discountType === 'PercentageOff' &&
        !!discountValue &&
        discountValue > 100) ||
      !division ||
      !startDate ||
      !endDate ||
      !startTime ||
      !endTime
    )
  }, [
    promoMessage,
    gmvForecast,
    discountValue,
    division,
    startDate,
    endDate,
    startTime,
    endTime,
    discountType,
  ])

  const handleSubmit = () => {
    const payload = {
      ...selectedPromotion,
      umbrella_label: promoMessage,
      start_date: new Date(
        `${format(new Date(startDate), 'yyyy-MM-dd')}T${startTime}-08:00`,
      ).toISOString(),
      end_date: new Date(
        `${format(new Date(endDate), 'yyyy-MM-dd')}T${endTime}-08:00`,
      ).toISOString(),
      details: [{ discount_type: discountType, discount_value: discountValue }],
      gmv: gmvForecast,
      merchandise_hierarchies: [
        {
          division_id: division?.division_id,
          division_name: division?.division_name,
          pyramid_id: division?.group_id,
          pyramid_name: division?.group_name,
        },
      ],
    }

    if (selectedPromotion) {
      updatePromotion(payload as UpdatedPromotion)
        .then((response: any) => {
          if (response) {
            dispatch(
              showNotification({
                isShown: true,
                message: '3P promotion edited successfully',
                severity: 'success',
                autoClose: true,
              }),
            )
            onRequestClose()
            refreshData()
          }
        })
        .catch((error) => {
          dispatch(
            showNotification({
              isShown: true,
              message: error.response.data
                ? error.response.data?.errors[0]
                : error.messaage,
              severity: 'error',
              autoClose: true,
            }),
          )
          onRequestClose()
        })
    } else {
      addPromotion(payload)
        .then((response: any) => {
          if (response) {
            dispatch(
              showNotification({
                isShown: true,
                message: '3P promotion submitted successfully',
                severity: 'success',
                autoClose: true,
              }),
            )
            onRequestClose()
            refreshData()
          }
        })
        .catch((error) => {
          dispatch(
            showNotification({
              isShown: true,
              message: error.response.data
                ? error.response.data?.errors[0]
                : error.messaage,
              severity: 'error',
              autoClose: true,
            }),
          )
          onRequestClose()
        })
    }
  }

  return (
    <>
      <Card className="hc-bg-grey07 hc-mt-normal hc-pa-normal">
        <StyledPromotionDetailsHeading size={4}>
          3P Promotion Details
        </StyledPromotionDetailsHeading>
        <StyledForm className="hc-mt-normal ">
          <Form.Field
            label="Promo ID & Name"
            type="text"
            disabled
            value={promoIdAndName}
          />
          <Form.Field
            onChange={(event: any) => setPromoMessage(event.target.value)}
            label="Umbrella (Promo Message) *"
            type="text"
            value={promoMessage}
          />

          <Grid.Container className="hc-mt-dense">
            <Grid.Item className="hc-pv-none hc-ph-normal" xs={6}>
              <Form.Field
                type="select"
                label="Discount Type*"
                onUpdate={(_id: any, value: any) => setDiscountType(value)}
                options={[
                  {
                    value: 'PercentageOff',
                    label: 'Percentage Off',
                  },
                  { value: 'DollarOff', label: 'Dollar Off' },
                  { value: 'FixedPrice', label: 'Fixed Price' },
                ]}
                value={discountType}
              />
            </Grid.Item>
            <Grid.Item className="hc-pv-none hc-ph-normal" xs={6}>
              <label className="C-Input--type-label" htmlFor="discount-value">
                Discount value*
              </label>
              <StyledCurrencyInput
                className={
                  discountType === 'PercentageOff' ? 'percentage' : 'dollar'
                }
              >
                <StyledFormField
                  className="hc-mb-normal"
                  onChange={(event: any) =>
                    setDiscountValue(event.target.value)
                  }
                  error={
                    discountType === 'PercentageOff' &&
                    !!discountValue &&
                    discountValue > 100
                  }
                  errorText="Percent Off cannot be greater than 100."
                  id="discount-value"
                  type="number"
                  value={discountValue}
                />
              </StyledCurrencyInput>
            </Grid.Item>
          </Grid.Container>

          <label className="C-Input--type-label hc-mt-dense">Division*</label>
          <DivisionTypeahead
            data-testid="division-change"
            onChange={handleDivisionChange}
            value={division}
          />

          <Grid.Container className="hc-mt-expanded">
            <Grid.Item className="hc-pv-none hc-ph-normal" xs={6}>
              <DatePicker
                id="start-date"
                label="Start Date *"
                value={startDate}
                onUpdate={(_id, value) => {
                  setStartDate(value)
                }}
              />
            </Grid.Item>
            <Grid.Item className="hc-pv-none hc-ph-normal" xs={6}>
              <label className="C-Input--type-label" htmlFor="start-time">
                Time (PST)*
              </label>
              <input
                id="start-time"
                data-testid="start-time"
                className="time-input"
                type="time"
                value={startTime}
                onChange={(event: any) => setStartTime(event.target.value)}
              />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container className="hc-mt-expanded">
            <Grid.Item className="hc-pv-none hc-ph-normal" xs={6}>
              <DatePicker
                id="end-date"
                label="End Date *"
                value={endDate}
                onUpdate={(_id, value) => {
                  setEndDate(value)
                }}
                disableDates={{ before: startDate?.toString() }}
              />
            </Grid.Item>

            <Grid.Item className="hc-pv-none hc-ph-normal" xs={6}>
              <label className="C-Input--type-label ">Time (PST)*</label>
              <input
                className="time-input"
                data-testid="end-time"
                type="time"
                value={endTime}
                onChange={(event: any) => setEndTime(event.target.value)}
              />
            </Grid.Item>
          </Grid.Container>

          <Form.Field
            className="hc-mt-expanded"
            disabled
            label="Offer Channel *"
            type="text"
            value="3P"
          />

          <>
            <label
              className="C-Input--type-label hc-mt-normal"
              htmlFor="gmv-forecast"
            >
              GMV Forecast ($) *
            </label>

            <StyledCurrencyInput className="dollar">
              <StyledFormField
                className="hc-mb-normal"
                id="gmv-forecast"
                onChange={(event: any) => setGmvForecast(event.target.value)}
                type="number"
                value={gmvForecast}
              />
            </StyledCurrencyInput>
          </>
          <Button
            className="hc-mr-normal"
            onClick={handleSubmit}
            type="primary"
            disabled={isFormValid}
          >
            SAVE
          </Button>
          <Button onClick={onRequestClose} type="secondary">
            CANCEL
          </Button>
        </StyledForm>
      </Card>
    </>
  )
}

export default PromotionDetailsForm
