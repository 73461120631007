import Grid from '@mui/material/Grid'

import HeaderTitle from 'components/common/HeaderTitle'
import ReferralFeeContent from './ReferralFeeContent'

export const ReferralFeePage = () => {
  return (
    <div>
      <HeaderTitle title="Referral Fees" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferralFeeContent />
        </Grid>
      </Grid>
    </div>
  )
}

export default ReferralFeePage
