import styled from '@emotion/styled'
import { Grid, Card, Button } from '@enterprise-ui/canvas-ui-react'

export const StyledSellerPromotionsPage = styled(Grid.Container)`
  .border-all {
    border: 1px solid var(--canvas-border-color--default);
  }

  .border-top {
    border-top: 1px solid var(--canvas-border-color--default);
  }

  .border-bottom {
    border-bottom: 1px solid var(--canvas-border-color--default);
  }

  .border-right {
    border-right: 1px solid var(--canvas-border-color--default);
  }
  .border-left {
    border-left: 1px solid var(--canvas-border-color--default);
  }
  .border-radius {
    border-radius: 8px;
  }
`

export const StyledCard = styled(Card)`
  &.C-Card {
    height: 100%;
    position: relative;
  }
`

export const StyledSubtypeButton = styled(Button)`
  &.C-Button.\--type-ghost:hover {
    background: transparent;
    text-decoration: underline;
  }
`

export const StyledButtons = styled.div`
  position: absolute;
  bottom: 12px;
`
export const StyledProdTypesContainer = styled(Grid.Container)`
  &.C-GridContainer.\--spacing-normal {
    width: 100%;
    margin: 0px;
  }
`
