import { create } from 'zustand'
import { format, add, isAfter, isWithinInterval } from 'date-fns'

import { datePattern } from 'v2/constant/date'
import { getDateSubtractedByDays, getTargetFiscalYear } from 'v2/utils/date'

type TimeFrame = {
  startDate: string
  endDate: string
}

type SelectedTopSkuTypeColumns = {
  selectAll: boolean
  tcin: boolean
  upc: boolean
  category: boolean
  division: boolean
  department: boolean
}

type TopItemTypeOptions = {
  id: string
  value: string
  label: string
}

type View = 'INTERNAL' | 'EXTERNAL'

interface ReturnInsightsState {
  divisionId: string | null
  categoryId: string | null
  interval: string
  view: View
  selectedVmmId: string // the variable gets updated when the user selects a seller filter in internal view
  selectedSellerId: string
  compareInterval: string
  compareDatePickerDisableDate: string
  itemTypeIds: string[]
  topItemTypeIds: string[]
  partnerReturnRateItemTypeIds: string[]
  topItemTypeOptions: TopItemTypeOptions[]
  timeFrame: TimeFrame
  compareTimeFrame: TimeFrame | null
  isCompareViewEnabled: boolean
  selectedTopSkuTypeColumns: SelectedTopSkuTypeColumns
  updateTopSkuTypeColumns: (
    selectedTopSkuTypeColumns: SelectedTopSkuTypeColumns,
  ) => void
  updateView: (view: View) => void
  updateSellerId: (selectedSellerId: string) => void
  updateVmmId: (selectedVmmId: string) => void
  updateCompareView: (isCompareViewEnabled: boolean) => void
  updateDivisionId: (divisionId: string | null) => void
  updateCategoryId: (category: string | null) => void
  updateTimeFrame: (timeFrame: TimeFrame) => void
  updateCompareTimeFrame: (compareTimeFrame: TimeFrame) => void
  updateTopItemTypeOptions: (topItemTypeOptions: TopItemTypeOptions[]) => void
  updateItemTypeIds: (itemTypeId: string[]) => void
  updateTopItemTypeIds: (topItemTypeIds: string[]) => void
  updatePartnerReturnRateItemTypeIds: (
    partnerReturnRateItemTypeIds: string[],
  ) => void
  dateQuickSelectionRange: any[]
  clearCompareInterval: () => void
}

const todayDate = new Date()
const targetFiscalYear = getTargetFiscalYear(todayDate)

const formatInteraval = (startDate: Date, endDate: Date) =>
  `${format(startDate, datePattern.minus.yyyy_MM_dd)}/${format(
    endDate,
    datePattern.minus.yyyy_MM_dd,
  )}`

// Fiscal Year starts from Feb 1 to Jan 31
const isQuarterAvailable = (quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'): boolean => {
  const q1Start = new Date(targetFiscalYear, 1, 1) // Feb 1 of current year
  const q2Start = new Date(targetFiscalYear, 4, 1) // May 1 of current year
  const q3Start = new Date(targetFiscalYear, 7, 1) // Aug 1 of current year
  const q4Start = new Date(targetFiscalYear, 10, 1) // Nov 1 of current year

  // Calculate the 30-day lag periods for each quarter
  const q1AvailableDate = add(q1Start, { days: 29 })
  const q2AvailableDate = add(q2Start, { days: 29 })
  const q3AvailableDate = add(q3Start, { days: 29 })
  const q4AvailableDate = add(q4Start, { days: 29 })

  // Check for the specific quarter passed as a parameter
  switch (quarter) {
    case 'Q1':
      return isAfter(todayDate, q1AvailableDate)
    case 'Q2':
      return isAfter(todayDate, q2AvailableDate)
    case 'Q3':
      return isAfter(todayDate, q3AvailableDate)
    case 'Q4':
      return isAfter(todayDate, q4AvailableDate)
    default:
      return false
  }
}

const getQuarterEndDate = (quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4') => {
  const q1Start = new Date(targetFiscalYear, 1, 1) // Feb 1 of current year
  const q2Start = new Date(targetFiscalYear, 4, 1) // May 1 of current year
  const q3Start = new Date(targetFiscalYear, 7, 1) // Aug 1 of current year
  const q4Start = new Date(targetFiscalYear, 10, 1) // Nov 1 of current year

  const q1End = new Date(targetFiscalYear, 3, 30) // Apr 30 of current year
  const q2End = new Date(targetFiscalYear, 6, 31) // Jul 31 of current year
  const q3End = new Date(targetFiscalYear, 9, 31) // Oct 31 of current year
  const q4End = new Date(targetFiscalYear + 1, 0, 31) // Jan 31 of next year

  const isQ1InRange = isWithinInterval(todayDate, {
    start: q1Start,
    end: add(q1End, { days: 30 }), // Adding 30 days to the end date for handling the 30 days lag period
  })
  const isQ2InRange = isWithinInterval(todayDate, {
    start: q2Start,
    end: add(q2End, { days: 30 }), // Adding 30 days to the end date for handling the 30 days lag period
  })
  const isQ3InRange = isWithinInterval(todayDate, {
    start: q3Start,
    end: add(q3End, { days: 30 }), // Adding 30 days to the end date for handling the 30 days lag period
  })
  const isQ4InRange = isWithinInterval(todayDate, {
    start: q4Start,
    end: add(q4End, { days: 30 }), // Adding 30 days to the end date for handling the 30 days lag period
  })

  const quarterEndDate = {
    Q1: isQ1InRange ? getDateSubtractedByDays(todayDate, 30) : q1End,
    Q2: isQ2InRange ? getDateSubtractedByDays(todayDate, 30) : q2End,
    Q3: isQ3InRange ? getDateSubtractedByDays(todayDate, 30) : q3End,
    Q4: isQ4InRange ? getDateSubtractedByDays(todayDate, 30) : q4End,
  }

  return quarterEndDate[quarter]
}

const isFiscalYearEnabled = (): boolean => {
  const fiscalYearStart = new Date(targetFiscalYear, 1, 1) // Feb 1 of current year
  const fiscalYearEnableDate = add(fiscalYearStart, { days: 30 }) // 30 days after the fiscal year start date

  return isAfter(todayDate, fiscalYearEnableDate)
}

const getDefaultTimeFrame = (): TimeFrame => {
  if (isFiscalYearEnabled()) {
    return {
      startDate: format(
        new Date(targetFiscalYear, 1, 1),
        datePattern.slash.MM_dd_yyyy,
      ),
      endDate: format(
        getDateSubtractedByDays(todayDate, 30),
        datePattern.slash.MM_dd_yyyy,
      ),
    }
  }
  return {
    startDate: format(
      getDateSubtractedByDays(todayDate, 59),
      datePattern.slash.MM_dd_yyyy,
    ),
    endDate: format(
      getDateSubtractedByDays(todayDate, 30),
      datePattern.slash.MM_dd_yyyy,
    ),
  }
}

const formatDefaultInterval = () => {
  if (isFiscalYearEnabled()) {
    return formatInteraval(
      new Date(targetFiscalYear, 1, 1),
      getDateSubtractedByDays(todayDate, 30),
    )
  }
  return formatInteraval(
    getDateSubtractedByDays(todayDate, 59),
    getDateSubtractedByDays(todayDate, 30),
  )
}

const useReturnInsightsStore = create<ReturnInsightsState>((set) => ({
  view: 'INTERNAL',
  selectedVmmId: '',
  selectedSellerId: '',
  divisionId: null,
  categoryId: null,
  itemTypeIds: [],
  topItemTypeIds: [],
  topItemTypeOptions: [],
  partnerReturnRateItemTypeIds: [],
  interval: formatDefaultInterval(), // We will be sending this to the API
  timeFrame: getDefaultTimeFrame(),
  compareTimeFrame: null,
  compareInterval: '',
  selectedTopSkuTypeColumns: {
    selectAll: false,
    tcin: false,
    upc: false,
    category: false,
    division: false,
    department: false,
  },
  isCompareViewEnabled: false,
  updateTopItemTypeOptions: (topItemTypeOptions) => set({ topItemTypeOptions }),
  updateTopItemTypeIds: (topItemTypeIds) => set({ topItemTypeIds }),
  updateView: (view: View) => set({ view }),
  updateSellerId: (selectedSellerId: string) => set({ selectedSellerId }),
  updateVmmId: (selectedVmmId: string) => set({ selectedVmmId }),
  updateCompareView: (isCompareViewEnabled: boolean) =>
    set({ isCompareViewEnabled }),
  clearCompareInterval: () => set({ compareInterval: '' }),
  updateTopSkuTypeColumns: (
    selectedTopSkuTypeColumns: SelectedTopSkuTypeColumns,
  ) => set({ selectedTopSkuTypeColumns }),
  updateDivisionId: (divisionId) =>
    set({ divisionId, isCompareViewEnabled: false, compareInterval: '' }),
  updateCategoryId: (categoryId) =>
    set({ categoryId, isCompareViewEnabled: false, compareInterval: '' }),
  updateCompareTimeFrame: (compareTimeFrame: TimeFrame) =>
    set(() => {
      return {
        compareTimeFrame,
        compareInterval: formatInteraval(
          new Date(compareTimeFrame.startDate),
          new Date(compareTimeFrame.endDate),
        ),
      }
    }),
  updateTimeFrame: (timeFrame: TimeFrame) =>
    set(() => {
      return {
        timeFrame,
        isCompareViewEnabled: false,
        interval: formatInteraval(
          new Date(timeFrame.startDate),
          new Date(timeFrame.endDate),
        ),
        compareInterval: '',
      }
    }),
  updateItemTypeIds: (itemTypeIds) => set({ itemTypeIds }),
  updatePartnerReturnRateItemTypeIds: (partnerReturnRateItemTypeIds) =>
    set({ partnerReturnRateItemTypeIds }),
  compareDatePickerDisableDate: format(
    getDateSubtractedByDays(new Date(), 30),
    datePattern.slash.MM_dd_yyyy,
  ),
  dateQuickSelectionRange: [
    {
      label: 'Latest 30 days',
      startDate: getDateSubtractedByDays(todayDate, 59),
      endDate: getDateSubtractedByDays(todayDate, 30),
    },
    {
      label: 'Latest 60 days',
      startDate: getDateSubtractedByDays(todayDate, 89),
      endDate: getDateSubtractedByDays(todayDate, 30),
    },
    {
      label: 'Latest 90 days',
      startDate: getDateSubtractedByDays(todayDate, 119),
      endDate: getDateSubtractedByDays(todayDate, 30),
    },
    ...(isQuarterAvailable('Q1')
      ? [
          {
            label: 'Q1',
            startDate: new Date(targetFiscalYear, 1, 1),
            endDate: getQuarterEndDate('Q1'),
          },
        ]
      : []),
    ...(isQuarterAvailable('Q2')
      ? [
          {
            label: 'Q2',
            startDate: new Date(targetFiscalYear, 4, 1),
            endDate: getQuarterEndDate('Q2'),
          },
        ]
      : []),
    ...(isQuarterAvailable('Q3')
      ? [
          {
            label: 'Q3',
            startDate: new Date(targetFiscalYear, 7, 1),
            endDate: getQuarterEndDate('Q3'),
          },
        ]
      : []),
    ...(isQuarterAvailable('Q4')
      ? [
          {
            label: 'Q4',
            startDate: new Date(targetFiscalYear, 10, 1),
            endDate: getQuarterEndDate('Q4'),
          },
        ]
      : []),
    ...(isFiscalYearEnabled()
      ? [
          {
            label: 'Fiscal Year to Date',
            startDate: new Date(targetFiscalYear, 1, 1),
            endDate: getDateSubtractedByDays(todayDate, 30),
          },
        ]
      : []),
  ],
}))

export default useReturnInsightsStore
