import { Grid } from '@enterprise-ui/canvas-ui-react'
import HeaderTitle from 'components/common/HeaderTitle'
import { UnshippedPastDue } from 'v2/components/feature/OrdersDashboard'
import BreakdownSummary from 'v2/components/feature/OrdersDashboard/BreakdownSummary'
import Filter from 'v2/components/feature/OrdersDashboard/Filter'
import OrderDetails from 'v2/components/feature/OrdersDashboard/OrderDetails'
import OrdersToBeShipped from 'v2/components/feature/OrdersDashboard/OrdersToBeShipped'
const OrdersDashboard = () => {
  return (
    <>
      <HeaderTitle title="Orders" />
      <Grid.Container className="hc-mb-2x" direction="column">
        <Grid.Item xs={12} className="hc-pa-none">
          <Filter />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={12}>
          <BreakdownSummary />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="hc-mb-2x hc-mt-2x " justify="space-between">
        <Grid.Item className="hc-pa-none" xs={6}>
          <UnshippedPastDue />
        </Grid.Item>
        <Grid.Item className={'hc-pa-none'} xs={6}>
          <OrdersToBeShipped />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={12}>
          <OrderDetails />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default OrdersDashboard
