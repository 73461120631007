import axios from 'axios'

import { url } from 'v2/utils'
import apiConfig from 'config/apiConfig'

interface ItemTypeParams {
  name?: string
  type: string
  nameSearchType?: string
  status?: string
  parentId?: string
  depth?: number
  perPage?: number
  target?: string
}

export interface ItemTypeTaxonomies {
  id: string
  label: string
  value: string
}

export async function getItemTypeTaxonomies(
  params: ItemTypeParams,
): Promise<ItemTypeTaxonomies[]> {
  const {
    depth,
    name,
    parentId,
    perPage = 15,
    target = 'external',
    status = 'ACTIVE',
    nameSearchType = 'STARTS_WITH',
  } = params

  const queryParams = url.buildURLQueryParams({
    status,
    depth,
    target,
    name,
    name_search_type: nameSearchType,
    parent_id: parentId,
    per_page: perPage,
  })

  try {
    const { data } = await axios.get(
      `${apiConfig.nexus}/taxonomies?${queryParams}`,
    )

    const itemTypeTaxonomiesData: ItemTypeTaxonomies[] = data?.map(
      ({ id, name }: any) => ({
        id,
        value: id,
        label: name,
      }),
    )

    return itemTypeTaxonomiesData
  } catch (error) {
    return Promise.reject([])
  }
}
