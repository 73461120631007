import styled from '@emotion/styled'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'

export const StyledCard = styled(Card)`
  border: 0.5px solid var(--canvas-font-color--interactive-contrast);
  border-bottom: 6px solid var(--canvas-font-color--interactive);
  border-radius: 4px;
  width: 160px !important;
  height: 80px !important;
`
export const StyledGridContainerFilter = styled(Grid.Container)`
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
`
export const StyledOrderToBeShippedContainer = styled(Card)`
  &.C-Card {
    min-height: 430px;
  }
  .C-Input--type-label {
    font-size: 16px;
    font-weight: bold;
`
export const StyledFilterContainer = styled(Card)`
  &.C-Card {
    border-radius: 0;
  }
  .C-Heading {
    font-weight: 600;
    text-transform: uppercase;
  }
`
