import styled from '@emotion/styled'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

export const StyledDatePicker = styled(DatePicker)`
  .C-Input {
    border: 1px solid var(--canvas-border-color--default-hover);
    border-radius: 4px;
    font-size: 14px;
    padding: 17px;
  }
  .C-DatePicker__MonthIcons {
    top: 32px;
  }
  .C-Input--type-label {
    text-align: left;
    margin-top: 1px;
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 14px;
  }

  .C-GridContainer,
  .C-Table .C-TableRow {
    flex-wrap: nowrap;
  }

  .C-GridItem,
  .C-TableData,
  .C-TableHeader {
    width: max-content;
  }
`
