import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import {
  Bar,
  Tooltip,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { roundToTwoDecimals } from 'v2/utils/helper'
import { StyledCardContainer } from '../../ReturnInsights/styles'

const dummyData = [
  {
    x_axis: 'Costway',
    y_axis: 10.97666016553063,
    return_gmv: 1.2145732709999997e7,
  },
  {
    x_axis: 'Cupshe',
    y_axis: 46.91205219647689,
    return_gmv: 1.1735461560000023e7,
  },
  {
    x_axis: 'Unique Bargains',
    y_axis: 35.86975549976599,
    return_gmv: 9070435.700000023,
  },
  {
    x_axis: 'Nuloom',
    y_axis: 25.27528550579781,
    return_gmv: 8156656.770000004,
  },
  {
    x_axis: 'Spreetail',
    y_axis: 13.292731058587853,
    return_gmv: 6684780.910000009,
  },
]
const UnshippedPastDueGraphByPartner = () => {
  const [graphData, setGraphData] = useState<{}[]>()

  useEffect(() => {
    const data = dummyData.map((item: any) => ({
      ...item,
      y_axis: roundToTwoDecimals(item.y_axis),
    }))

    setGraphData(data)
  }, [graphData])

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          layout="vertical"
          data={graphData}
          margin={{ top: 30, right: 30, left: -40, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis className="hc-fs-min" dataKey="y_axis" dy={14} type="number" />
          <YAxis
            className="hc-fs-min"
            dataKey="x_axis"
            type="category"
            tick={{ fontSize: 11 }}
            width={140}
          />

          <Tooltip
            cursor={{ fill: 'transparent' }}
            labelFormatter={() => ''}
            formatter={(value: any) => [`${roundToTwoDecimals(value)}`]}
          />
          <Bar
            dataKey="y_axis"
            fill="#888888"
            barSize={20}
            radius={[0, 5, 5, 0]}
          >
            <LabelList
              className="hc-fs-min"
              dataKey="y_axis"
              position="insideRight"
              fill="var(--canvas-background-color--input)"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Grid.Container className="hc-mt-normal" align="center">
        <StyledCardContainer className="hc-bg-grey02 hc-ml-2x hc-pa-dense" />
        <Grid.Item>
          <p className="hc-fs-min hc-txt-capitalize">Order Count</p>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
export default UnshippedPastDueGraphByPartner
