import {
  isOneOfUserRoles,
  isUserRoleExternal,
  isUserRoleProductAdmin,
} from './authorization'
import { approvedToListStatuses } from './seller'

import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_COMMUNICATIONS,
} from './roles'

import { Source, SellerStatus } from 'types/Seller'
import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'
import { flag, FlagName } from 'flag'

export const hasErrorConfigPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasHomePage = (memberOf: string[]) => !isUserRoleExternal(memberOf)

export const hasItemUploadPage = (
  memberOf: string[],
  source?: string,
  status?: SellerStatus,
) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
    USER_ROLE_APP_SMS_ADMIN,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_ITEM_APPROVER,
  ]) &&
  (source === Source.DIRECT_FROM_SELLER || source === Source.COMMERCEHUB) &&
  status &&
  [...approvedToListStatuses, SellerStatus.PARTNER_SETUP].includes(status)

export const hasSalesMetricsPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf) ||
  isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN])

export const hasFeedbackPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN]) || isUserRoleExternal(memberOf)

export const hasReturnDisputeCasesPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasPromotions = (memberOf: string[], sellerId?: string) => {
  if (
    apiConfig.environment === ENVIRONMENT_PRODUCTION &&
    isUserRoleExternal(memberOf) &&
    sellerId
  ) {
    // Partners for Pilot Launch

    const sellerIdsList = [
      '602ff8e5cdf1cf72b4dbd2e6', // Reebok
      '5ff3f1d4193ba547e817a366', // Unique Bargains
      '645d292bd2c1665189b4068c', // Simply Sage Market
      '660d8e892d60c36d1d85c097', // Infans
      '628e160622ae7325efbf3086', // AncestryDNA
      '60aea987cc296b26c7521da5', // Beacon Beauty Center
      '606f4b6c9e18960f21a95a77', // Joyin
      '60091a2b0dde462386d7803b', // Costway
      '5b5b1d3cbc563e03bad44170', // Spreetail
      '5d4846673d753e0097ddef86', // 232 Inc
      '64515851df7a01011bb47b9e', // Whizmax
      '65aa9f217a1b092f304dfb27', // Serwall Home Co LTD
      '5fb4e972fd35233bfd8806e9', // Best Choice Product
      '61dd12d55d89c53cffa3e7b3', // I enjoy Home
      '66a3def9b5b869120a0ad9e8', // Acer
    ]

    return flag(FlagName.PROMO_VISIBILITY) && sellerIdsList.includes(sellerId)
  } else {
    return flag(FlagName.PROMO_VISIBILITY)
  }
}

export const hasItemProcessingPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_ITEM_APPROVER,
  ])

export const hasItemVersionPage = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasKafkaTopicsSearchPage = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasUtilitiesNav = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_COMMUNICATIONS,
    USER_ROLE_OPS,
  ])

export const hasBarcodeReleasePage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ITEM_MANAGER,
  ])

export const hasUnlistItemsPageAccess = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
  ])

export const hasPhoneEditPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasAlertManagementPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_COMMUNICATIONS])

export const hasTimeline = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasReturnShippingRatesNav = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasHolidayClosuresPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasOnboardingPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasShippingMarkupPage = (memberOf: string[]) =>
  flag(FlagName.BUY_SHIPPING_MARKUP) && !isUserRoleExternal(memberOf)

export const hasReturnsResearchPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasReturnDashboardInternalAccess = () =>
  flag(FlagName.RETURN_INSIGHT_DASHBOARD_INTERNAL)

export const hasReturnDashboardExternalAccess = (sellerId: string = '') => {
  if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
    if (sellerId) {
      // Partners for Pilot Launch
      const sellersList = [
        '5ff3f1d4193ba547e817a366', // Unique Bargains
        '6298af4d12e0625a84a39c94', // Yaheetech
        '5c5d9b9b802fef024ac5b6b5', // Nourison
        '5dcc71c371dc7a008d3cc45a', // Nuloom
        '5ebef05064a9cf12e8cd4373', // MyOfficeInnovations
        '60aea987cc296b26c7521da5', // Beacon Beauty Center
        '63b5aa893804b0212e92bc84', // Dream On Me
        '5f19bccba16c9c64279e621b', // Swanson Health
        '6396fb60bbd6096258c40934', // Rani Brand
        '5bb55281da0f3803ba59b154', // Musician's Friend
        '5ed54daf96948360f5aabd1f', // SCS Direct Inc.
        '65248bc877a79b71571281b8', // Flying Star LLC
        '64503399b77bb03ad8c0dc45', // Super Deals
      ]
      return (
        flag(FlagName.RETURN_INSIGHT_DASHBOARD_EXTERNAL) &&
        sellersList.includes(sellerId)
      )
    } else {
      return flag(FlagName.RETURN_INSIGHT_DASHBOARD_EXTERNAL)
    }
  } else {
    return flag(FlagName.RETURN_INSIGHT_DASHBOARD_EXTERNAL)
  }
}

export const hasOrderDashboardInternalAccess = () =>
  flag(FlagName.ORDER_DASHBOARD_INTERNAL)

export const hasOrderDashboardExternalAccess = () =>
  flag(FlagName.ORDER_DASHBOARD_EXTERNAL)

export const hasTgidConsentPageAccess = (memberOf: string[]) =>
  flag(FlagName.TGID_CONSENT) &&
  (!isUserRoleExternal(memberOf) ||
    isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN]))
