import { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'

import StyledIcon from 'components/common/StyledIcon'
import { DialogEnum } from 'components/common/Dialog'

import theme from 'config/themeConfig'

import { Carrier } from 'types/Carrier'

import upsImage from '../../images/UPS-logo.png'
import fedexImage from '../../images/fedex.png'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import { Markup } from 'types/Markup'
import { showNotification } from 'store/notification/reducer'
import { DATE_DISPLAY_FORMAT, formatDateLocalTime } from 'services/dateService'
import { formatPercentString } from 'components/common/EnhancedTable/formatters'

export interface Props {
  carrier: Carrier
  effectiveMarkup: Markup | undefined
  upcomingMarkup: Markup | undefined
  refreshData: () => void
}

export const CarrierMarkupCard = ({
  carrier,
  effectiveMarkup,
  upcomingMarkup,
  refreshData,
}: Props) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const getCarrierImage = (carrier: Carrier) => {
    switch (carrier) {
      case Carrier.UPS:
        return (
          <img
            src={upsImage}
            alt="ups shipping logo"
            style={{
              width: '41px',
              height: 'auto',
            }}
          />
        )
      case Carrier.FEDEX:
        return (
          <img
            src={fedexImage}
            alt="fedex shipping logo"
            style={{
              width: '88px',
              height: 'auto',
              paddingTop: '8px',
            }}
          />
        )
      default:
        return undefined
    }
  }

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDialogClose = (newMarkup: Markup) => {
    dispatch(closeDialog())

    dispatch(
      showNotification({
        isShown: true,
        message: `${
          newMarkup.carrier === Carrier.FEDEX ? 'FedEx' : newMarkup.carrier
        } carrier markup rate has been updated.\n
        ${formatPercentString(
          newMarkup.rate,
        )} will take effect on ${formatDateLocalTime(
          newMarkup.start_date,
          DATE_DISPLAY_FORMAT,
        )} at 12am CST`,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        severity: 'success',
        autoClose: true,
      }),
    )

    refreshData()
  }

  const handleEditMarkup = () => {
    handleMenuClose()

    dispatch(
      openDialog({
        dialogEnum: DialogEnum.EDIT_CARRIER_MARKUP,
        componentProps: {
          carrierMarkup: effectiveMarkup,
          handleClose: handleDialogClose,
        },
      }),
    )
  }

  const handleViewMarkupHistory = () => {
    handleMenuClose()

    dispatch(
      openDialog({
        dialogEnum: DialogEnum.VIEW_CARRIER_MARKUP_HISTORY,
        componentProps: {
          carrier,
          handleClose: handleDialogClose,
        },
      }),
    )
  }

  return (
    <Card
      className="hc-pt-md"
      data-testid={`${carrier}-card`}
      style={{ height: '80px' }}
    >
      <Grid container>
        <Grid item xs={3} pl={3}>
          {getCarrierImage(carrier)}
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="subtitle2"
            fontWeight={700}
            color={theme.palette.grey[500]}
          >
            Carrier
          </Typography>
          <Typography variant="h4" fontWeight={700}>
            {carrier === Carrier.FEDEX ? 'FedEx' : carrier}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="subtitle2"
            fontWeight={700}
            color={theme.palette.grey[500]}
          >
            Carrier Markup %
          </Typography>
          <Grid item sx={{ display: 'inline-flex' }}>
            <Typography variant="h4" fontWeight={700}>
              {effectiveMarkup?.rate
                ? `${formatPercentString(effectiveMarkup.rate)}`
                : undefined}
            </Typography>
            {upcomingMarkup && (
              <Tooltip
                title={`Markup Update: Carrier markup will update to ${formatPercentString(
                  upcomingMarkup.rate,
                )} on ${formatDateLocalTime(
                  upcomingMarkup.start_date,
                  DATE_DISPLAY_FORMAT,
                )} at 12am CST`}
              >
                <UpdateIcon sx={{ color: theme.palette.error.main, ml: 0.5 }} />
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} style={{ paddingRight: '20px' }} textAlign="end">
          <IconButton
            aria-label="table action menu"
            data-testid="table-action-menu"
            size="large"
            onClick={handleMenuOpen}
          >
            <StyledIcon iconType="menu" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={handleEditMarkup}>Edit Carrier Markup</MenuItem>
            <MenuItem onClick={handleViewMarkupHistory}>
              View Markup History
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CarrierMarkupCard
