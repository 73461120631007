import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'

import { PromotionsReport } from 'types/Promotion'
import { saveReportAsFile } from 'services/promotions'
export interface Props {
  text: string
  report: PromotionsReport
  title: string
  hasDateRange: boolean
  isDisabled: boolean
  sellerName?: string
}

export const DownloadReportButton = ({ report, isDisabled }: Props) => {
  const tooltipTitle = isDisabled
    ? 'You do not have access to this report'
    : 'Download report'

  return (
    <div className="hc-ma-none">
      <Tooltip
        data-testid="download-button"
        content={tooltipTitle}
        location="bottom-left"
      >
        <div>
          <IconButton
            size="small"
            color="primary"
            onClick={() =>
              saveReportAsFile({
                report,
              })
            }
            title="Download report"
            disabled={isDisabled}
            data-testid="download-icon-button"
          >
            <DownloadIcon color={isDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  )
}
export default DownloadReportButton
