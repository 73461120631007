import StyledIcon from 'components/common/StyledIcon'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'

import { ReferralPercentage } from 'types/ReferralFee'
import { StyledIconButton } from 'components/BaseReferralFeesPage/styles'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'
import { formatPercentage } from 'components/ReferralFeesV2/helpers'

interface Props {
  table: TableHook
  data: ReferralPercentage[]
  total: number
  isPending: boolean
  canEdit?: boolean
  edit?: (element: ReferralPercentage) => (event: any) => void
  handleOnEditClick?: (title: string, rowItem?: ReferralPercentage) => void
  viewHistory?: (element: ReferralPercentage) => (event: any) => void
}

export const ReferralFeeTable = ({
  table,
  data,
  total,
  isPending,
  canEdit,
  handleOnEditClick,
  viewHistory,
  edit,
}: Props) => {
  const fieldList: EnhancedTableFieldType<ReferralPercentage>[] = [
    {
      key: 'itemTypeName',
      heading: 'Item Type',
      minWidth: 40,
    },

    {
      key: 'start_date',
      heading: 'Start Date',
      hasSort: true,
      minWidth: 40,
      formatCell: formatDateMDYT('start_date'),
    },
    {
      key: 'created_by',
      heading: 'Last Modified By',
      hasSort: true,
      minWidth: 40,
    },
    {
      key: 'referral_percentage',
      heading: 'Referral Fee',
      hasSort: true,
      minWidth: 20,
      formatCell: (rowItem) => (
        <div>
          {rowItem.referral_percentage === 0
            ? 'NA'
            : `${formatPercentage(rowItem.referral_percentage)}%`}
        </div>
      ),
    },
    {
      key: 'price_range_referral_percentages',
      heading: 'Price Range and Referral Fee',
      hasSort: true,
      minWidth: 20,
      formatCell: (rowItem) =>
        rowItem.price_range_referral_percentages
          ? rowItem.price_range_referral_percentages?.map((item) => {
              const referral_percentage =
                item?.referral_percentage &&
                formatPercentage(Number(item?.referral_percentage))

              return (
                <div className="hc-pb-min">
                  ${item.min_price}-${item.max_price} : {referral_percentage}%
                </div>
              )
            })
          : 'NA',
    },
  ]

  if (canEdit) {
    fieldList.push({
      key: 'edit',
      heading: '',
      minWidth: 10,
      formatCell: (rowItem) => (
        <StyledIconButton
          data-testid="edit-referrel-fee-button"
          color="primary"
          key="edit-referral-fees"
          aria-label="edit-referral-fees"
          onClick={() => handleOnEditClick?.('Edit Referral Fees', rowItem)}
        >
          <EnterpriseIcon icon={PencilIcon} color="blue" />
        </StyledIconButton>
      ),
    })
  }

  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        fieldListContext={{ handleOnEditClick }}
        paginationDisabled={false}
        data={data}
        isLoading={isPending}
        isUpdatedTable
        actions={[
          {
            callback: edit,
            label: 'Edit',
            icon: <StyledIcon iconType="edit" />,
          },
          {
            callback: viewHistory,
            label: 'View History',
            icon: <StyledIcon iconType="history" />,
          },
        ]}
      />
    </TableSpacer>
  )
}

export default ReferralFeeTable
