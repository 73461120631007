import { useCallback } from 'react'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'store/dialog/actionCreator'
import ChangeHistoryTable from 'components/ReferralFeesV2/ChangeHistory/ChangeHistoryTable'
import useTable from 'components/common/EnhancedTable/useTable'
import { Direction } from 'services/pageableHelper'
import { useQuery } from '@tanstack/react-query'
import { getReferralBasePercentages } from 'services/referralBasePercentages'
import { StyledDivider } from 'components/ReferralFeesV2/styles'
import { Button, ButtonProps, Grid } from '@enterprise-ui/canvas-ui-react'
import { DATE_DISPLAY_FORMAT_TIME, formatDate } from 'services/dateService'
import { ReferralTypeEnum } from '..'

interface Props {
  isOpen: boolean
  subtypeId?: string
  subtypeName?: string
  itemTypeId?: string
  itemTypeName?: string
  isSubtype?: ReferralTypeEnum
}

export const PriceRangeBaseFeeChangeHistory = ({
  isOpen,
  subtypeId,
  subtypeName,
  itemTypeId,
  itemTypeName,
  isSubtype,
}: Props) => {
  const dispatch = useDispatch()

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'last_modified',
    page: 0,
    perPage: 20,
  })

  const { data: history, isLoading } = useQuery(
    isSubtype
      ? ['GET_PRICE_RANGE_FEE_HISTORY', subtypeId, table]
      : ['GET_PRICE_RANGE_FEE_HISTORY', itemTypeId, table],
    () => {
      return isSubtype
        ? getReferralBasePercentages(table.state, false, subtypeId)
        : getReferralBasePercentages(table.state, false, undefined, itemTypeId)
    },
  )

  const exportAsExcel = useCallback(() => {
    let csv = ['Start Date, Last Modified By, Base Fee, Referral Fees']

    const csvValue =
      history?.data.map(
        (dataItem) =>
          `${formatDate(
            dataItem.start_date,
            DATE_DISPLAY_FORMAT_TIME,
          )},${dataItem?.last_modified_by}, ${(
            dataItem?.referral_percentage * 100
          ).toFixed()}, ${JSON.stringify(
            dataItem.price_range_referral_percentages,
          )}`,
      ) ?? []
    csv = [...csv, ...csvValue]

    const csvFile = csv.join('\r\n')

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvFile)
    hiddenElement.target = '_blank'
    hiddenElement.download = `change-history-${
      isSubtype ? subtypeName : itemTypeName
    }.csv`
    hiddenElement.click()
  }, [history, subtypeName, itemTypeName, isSubtype])

  const DownloadReport = ({ children }: ButtonProps) => (
    <Button
      children={children}
      key="change-history-button"
      data-testid="change-history-button"
      type="secondary"
      size="normal"
      variant="contained"
      onClick={exportAsExcel}
    />
  )

  return (
    <FullScreenDialogContainer
      title="Change History"
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeDialog())}
    >
      <Grid.Container justify="space-between">
        <Grid.Item>
          <h2>{isSubtype ? subtypeName : itemTypeName}</h2>
        </Grid.Item>
        <Grid.Item>
          <DownloadReport>DOWNLOAD REPORT</DownloadReport>
        </Grid.Item>
      </Grid.Container>

      <StyledDivider />
      <ChangeHistoryTable
        table={table}
        data={history?.data ?? []}
        isPending={isLoading}
        total={history?.total ?? 0}
      />
    </FullScreenDialogContainer>
  )
}

export default PriceRangeBaseFeeChangeHistory
