import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { toTitleCase } from 'v2/utils/helper'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { postSellerExecuteCard } from 'v2/services'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'

export type GreenFieldDivisionAutoCompleteOptionType = {
  id: string
  value: string
  label: string
}

const useGreenFieldDivisionAutoComplete = () => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const [divisionOptions, setDivisionOptions] = useState<
    GreenFieldDivisionAutoCompleteOptionType[]
  >([])
  const [selectedDivisionOption, setSelectedDivisionOption] =
    useState<GreenFieldDivisionAutoCompleteOptionType | null>(null)

  useEffect(() => {
    const config = {
      sellerId,
      cardId: GREEN_FIELD_CARD_ID.DIVISION_FILTER,
      payload: {
        filters: [
          ...(vmmId
            ? [
                {
                  dimension: 'vendor_id',
                  pattern: String(vmmId),
                  type: 'in',
                },
              ]
            : []),
        ],
      },
    }
    postSellerExecuteCard(config).then(
      (response: { division_id: string; division_name: string }[]) => {
        const divisions = response?.map(({ division_id, division_name }) => ({
          id: division_id,
          value: division_id,
          label: toTitleCase(division_name),
        }))
        setDivisionOptions(divisions)
      },
    )
  }, [sellerId, vmmId])

  const onDivisionUpdate = (
    _id: string,
    value: GreenFieldDivisionAutoCompleteOptionType,
  ) => {
    setSelectedDivisionOption(value)
  }

  return {
    selectedDivisionOption,
    divisionOptions,
    onDivisionUpdate,
  }
}

export default useGreenFieldDivisionAutoComplete
