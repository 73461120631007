import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import SummaryCard from './SummaryCard'

const BreakdownSummary = () => {
  return (
    <Grid.Container direction="column">
      <Grid.Item>
        <Grid.Container justify="space-between" align="top">
          <p className="hc-fs-xl">Breakdown Summary</p>
          <Button type="secondary">Help Articles</Button>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item className="hc-pa-none hc-mb-lg">
        <p className="hc-fs-md">Total Orders : 10,000</p>
      </Grid.Item>
      <Grid.Item>
        <Grid.Container align="left" justify="space-between">
          {[1100, 2200, 3000, 440, 5000, 6233, 710, 890].map((x) => {
            return (
              <Grid.Item className="hc-pa-none hc-mr-xs">
                <SummaryCard
                  title="Unshipped Orders"
                  value={x.toString()}
                  tooltipText="this is imp"
                />
              </Grid.Item>
            )
          })}
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default BreakdownSummary
