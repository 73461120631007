import { Divider, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import PartnerReturnRateGraph from './PartnerReturnRateGraph'
import {
  StyledItemTypeGridItem,
  StyledReturnRateCardContainer,
} from 'v2/components/feature/ReturnInsights/styles'
import ReportDownload from 'v2/components/feature/ReturnInsights/ReportDownload'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useGreenFieldItemTypeAutoComplete } from 'v2/hooks/autoComplete'
import { useEffect } from 'react'
import { useReturnInsightsStore } from 'v2/store'

export const PartnerLevelReturnRate = () => {
  const { selectedItemTypOption, itemTypeOptions, onItemTypUpdate } =
    useGreenFieldItemTypeAutoComplete()

  const { updatePartnerReturnRateItemTypeIds, partnerReturnRateItemTypeIds } =
    useReturnInsightsStore()

  useEffect(() => {
    const itemTypeIds = selectedItemTypOption
      ? selectedItemTypOption?.map(({ id }) => id)
      : []
    updatePartnerReturnRateItemTypeIds(itemTypeIds)
  }, [updatePartnerReturnRateItemTypeIds, selectedItemTypOption])

  return (
    <StyledReturnRateCardContainer className="hc-pa-normal">
      <Grid.Container justify="space-between" align="top">
        <Grid.Item xs={6}>
          <Heading size={4}>PARTNER RETURN RATE</Heading>
          <p className="font-color-secondary hc-fs-xs">
            Top 10 Partners and their return rates in the selected time frame
          </p>
        </Grid.Item>
        <StyledItemTypeGridItem xs={4}>
          <Autocomplete
            multiselect
            placeholder="Select Item type"
            onUpdate={onItemTypUpdate}
            options={itemTypeOptions}
          />
        </StyledItemTypeGridItem>
        <Grid.Item className="hc-mt-min">
          <ReportDownload
            returnPerformanceReportTypes={['PARTNER_LEVEL_RETURN_RATE']}
            fileName="partner-level-return-rate"
            itemTypeIds={partnerReturnRateItemTypeIds}
          />
        </Grid.Item>
      </Grid.Container>
      <Divider className="hc-mt-xs" />
      <Grid.Item xs={12}>
        <PartnerReturnRateGraph />
      </Grid.Item>
    </StyledReturnRateCardContainer>
  )
}

export default PartnerLevelReturnRate
