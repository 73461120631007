import { useState, type SyntheticEvent } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'

import PromotionDetailsForm from './PromotionDetailsForm'
import { StyledDrawer } from './styles'
import Bulk3PPromoUpload from './Bulk3PPromoUpload'
import use3pPromotionsStore from 'v2/store/use3pPromotionsStore'

interface DrawerContainerProps {
  isVisible: boolean
  onRequestClose: () => void
  refreshData: () => void
}

const DrawerContainer = ({
  isVisible,
  onRequestClose,
  refreshData,
}: DrawerContainerProps) => {
  const { selectedPromotion } = use3pPromotionsStore()
  const [selectedTabName, setSelectedTabName] =
    useState<string>('EDIT PROMOTION')

  const onTabSelect = (_event: SyntheticEvent, tab: any) => {
    setSelectedTabName(tab.name)
  }

  return (
    <>
      <StyledDrawer
        headingText={`${selectedPromotion ? 'Edit' : 'Create'} 3P Promotion`}
        isVisible={isVisible}
        onRequestClose={onRequestClose}
      >
        <Tabs
          activeTab={selectedTabName}
          onTabSelect={onTabSelect}
          disableAdaptive
        >
          <Tabs.Item name="EDIT PROMOTION">
            {' '}
            {selectedPromotion ? 'EDIT PROMOTION' : 'ADD PROMOTION'}
          </Tabs.Item>
          {!selectedPromotion && (
            <Tabs.Item name="BULK UPLOAD">BULK UPLOAD</Tabs.Item>
          )}
        </Tabs>
        {selectedTabName === 'EDIT PROMOTION' && (
          <PromotionDetailsForm
            onRequestClose={onRequestClose}
            refreshData={refreshData}
          />
        )}
        {selectedTabName === 'BULK UPLOAD' && (
          <Bulk3PPromoUpload onRequestClose={onRequestClose} />
        )}
      </StyledDrawer>
    </>
  )
}

export default DrawerContainer
