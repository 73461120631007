import { FC, useState, useEffect } from 'react'
import {
  Heading,
  Grid,
  Button,
  ButtonGroup,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import { useGreenFieldItemTypeAutoComplete } from 'v2/hooks/autoComplete'
import { useReturnInsightsStore } from 'v2/store'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { StyledCardContainer, StyledItemTypeGridItem } from '../styles'
import ReportDownload from '../ReportDownload'
import TopSKUTypeTable from './TopSKUTypeTable'
import AddColumns from './AddColumns'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import { useSelector } from 'react-redux'
import { isRoleExternalUserSelector } from 'store/selectors'
interface OwnProps {
  isExternalView?: boolean
}

export enum ViewType {
  VC_VIEW = 'VC_VIEW',
  VAP_VIEW = 'VAP_VIEW',
}

const TopSKUType: FC<OwnProps> = ({ isExternalView = false }) => {
  const [view, setView] = useState<ViewType>(ViewType.VAP_VIEW)
  const { selectedItemTypOption, itemTypeOptions, onItemTypUpdate } =
    useGreenFieldItemTypeAutoComplete()
  const { updateItemTypeIds, topItemTypeOptions, itemTypeIds } =
    useReturnInsightsStore()
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  useEffect(() => {
    const itemTypeIds = (
      selectedItemTypOption?.filter(({ id }) => Boolean(id)) ?? []
    )?.map((ele) => ele.id)

    updateItemTypeIds(itemTypeIds)
  }, [updateItemTypeIds, selectedItemTypOption])

  const getItemTypeList = (value: any): string => {
    return value.map((val: any) => val.value ?? val.label).join(', ')
  }

  const handleItemTypeUpdate = (id: string, value?: any) => {
    onItemTypUpdate(id, value)

    const list = getItemTypeList(value)
    if (list.length > 0)
      logFireflyEvent(
        FireflyEvent.RETURN_INSIGHTS_ITEM_TYPE_SELECTION,
        'item_type-selection',
        list,
      )
  }

  const logFireflyEvent = (event: FireflyEvent, key?: string, data?: any) => {
    if (isExternalUser) {
      trackCustomEvent(event, key ?? event.toString(), data ?? '')
    }
  }
  return (
    <StyledCardContainer>
      <Grid.Container
        className="hc-pa-normal"
        align="center"
        justify="space-between"
      >
        <Grid.Item xs={5}>
          <Heading size={4}>TOP SKUs</Heading>
          <p className="hc-clr-grey02 hc-fs-xs">
            Top 10 SKUs surpassing the benchmark
          </p>
        </Grid.Item>
        <Grid.Item xs={7}>
          <Grid.Container align="center" justify="flex-end">
            <StyledItemTypeGridItem xs={6}>
              <Autocomplete
                multiselect
                value={topItemTypeOptions}
                placeholder="Select Item type"
                options={itemTypeOptions}
                onUpdate={handleItemTypeUpdate}
              />
            </StyledItemTypeGridItem>
            <Grid.Item>
              <ButtonGroup>
                <Button
                  onClick={() => setView(ViewType.VC_VIEW)}
                  type={view === ViewType.VC_VIEW ? 'primary' : 'secondary'}
                >
                  <Tooltip
                    content="Select this option for Variant Child + Standalone items"
                    location="top"
                  >
                    VC / SA View
                  </Tooltip>
                </Button>
                <Button
                  onClick={() => setView(ViewType.VAP_VIEW)}
                  type={view === ViewType.VAP_VIEW ? 'primary' : 'secondary'}
                >
                  <Tooltip
                    content="Select this option for Variant Parent + Standalone items"
                    location="top"
                  >
                    VAP / SA View
                  </Tooltip>
                </Button>
              </ButtonGroup>
            </Grid.Item>
            <Grid.Item>
              <AddColumns isExternalView={isExternalView} />
            </Grid.Item>
            <Grid.Item>
              <ReportDownload
                itemTypeIds={itemTypeIds}
                returnPerformanceReportTypes={[
                  view === ViewType.VC_VIEW
                    ? 'TOP_SKU_TYPES_VC_VIEW'
                    : 'TOP_SKU_TYPES_VAP_VIEW',
                ]}
                fileName={`top-sku-type-${
                  view === ViewType.VC_VIEW ? 'vc' : 'vap'
                }-view`}
                cardLevel={view}
                cardName={'TOP_SKUs'}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <TopSKUTypeTable
        view={view}
        cardId={
          view === ViewType.VC_VIEW
            ? GREEN_FIELD_CARD_ID.TOP_SKU_TYPE_VC_VIEW
            : GREEN_FIELD_CARD_ID.TOP_SKU_TYPE_VAP_VIEW
        }
      />
    </StyledCardContainer>
  )
}

export default TopSKUType
