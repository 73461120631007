import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { getUniqueListBy } from 'v2/utils/helper'

export const getDivisions = async () => {
  const config = {
    params: {
      per_page: 1000,
    },
  }

  try {
    const res = await axios.get(
      `${apiConfig.itemGroups}/merchandise_hierarchy_departments`,
      config,
    )

    const filteredDivisions = getUniqueListBy(res.data, 'division_id')

    return filteredDivisions
  } catch (err) {
    return Promise.reject(err)
  }
}
