import React from 'react'

import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import StyledIcon from '../StyledIcon'

import EnhancedTableCell from './EnhancedTableCell'

export interface TableActionItem {
  callback?: (element: any) => (event: any) => void
  label?: string
  icon?: JSX.Element
  labelCallback?: (item: any) => React.ReactNode
  hideAction?: (item: any) => boolean
}

interface Props {
  actions: TableActionItem[]
  isLoading: boolean
  rowItem: Dictionary<any>
}

export const EnhancedTableActionButtonCell = ({
  actions,
  rowItem,
  isLoading,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClick = (action: TableActionItem) => (event: any) => {
    handleMenuClose()
    if (action.callback) {
      action.callback(rowItem)(event)
    }
  }

  return (
    <EnhancedTableCell key="table-actions" variant="body">
      <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {actions.length === 1 && !actions[0]?.hideAction?.(rowItem) && (
          <IconButton
            aria-label={`table action ${actions[0].label}`}
            onClick={handleClick(actions[0])}
            data-testid={`table-action-${actions[0].label}`}
            disabled={isLoading}
            size="large"
          >
            {actions[0].icon}
          </IconButton>
        )}
        {actions.length > 1 && (
          <div>
            {actions.every((action) => action?.hideAction?.(rowItem)) ? (
              <></>
            ) : (
              <IconButton
                aria-label="table action menu"
                onClick={handleMenuOpen}
                data-testid="table-action-menu"
                disabled={isLoading}
                size="large"
              >
                <StyledIcon iconType="menu" />
              </IconButton>
            )}

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {actions.map((action) => {
                const hide = action?.hideAction?.(rowItem) ?? false

                if (hide) {
                  return null
                }

                return (
                  <MenuItem
                    data-testid={`table-action-menu-item-${action.label}`}
                    key={action.label}
                    onClick={handleClick(action)}
                  >
                    {action.labelCallback
                      ? action.labelCallback(rowItem)
                      : action.label}
                  </MenuItem>
                )
              })}
            </Menu>
          </div>
        )}
      </div>
    </EnhancedTableCell>
  )
}

export default EnhancedTableActionButtonCell
