import { FC, useEffect } from 'react'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import {
  useAsyncSellerSelect,
  useGreenFieldDivisionAutoComplete,
  useGreenFieldCategoryAutoComplete,
} from 'v2/hooks/autoComplete'
import { useReturnInsightsStore } from 'v2/store'
import { StyledFillterContainer, StyledDatePicker } from '../styles'
import Download from './Download'
import HelpArticle from './HelpArticle'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import { GreenFieldCategoryAutoCompleteOptionType } from 'v2/hooks/autoComplete/useGreenFieldCategoryAutoComplete'
import { useSelector } from 'react-redux'
import { isRoleExternalUserSelector } from 'store/selectors'

interface OwnProps {
  isExternalView?: boolean
}

const ReturnInsightsFilter: FC<OwnProps> = ({ isExternalView = false }) => {
  const { onDivisionUpdate, divisionOptions, selectedDivisionOption } =
    useGreenFieldDivisionAutoComplete()
  const { renderComponent, selectedOptions } = useAsyncSellerSelect()
  const { categoryOptions, onCategoryUpdate, selectedCategoryOption } =
    useGreenFieldCategoryAutoComplete()
  const {
    updateDivisionId,
    updateTimeFrame,
    updateCategoryId,
    timeFrame,
    dateQuickSelectionRange,
    compareDatePickerDisableDate,
    updateVmmId,
    updateSellerId,
  } = useReturnInsightsStore()
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  useEffect(() => {
    updateDivisionId(selectedDivisionOption?.id ?? null)
  }, [updateDivisionId, selectedDivisionOption?.id])

  useEffect(() => {
    updateCategoryId(selectedCategoryOption?.id ?? null)
  }, [updateCategoryId, selectedCategoryOption?.id])

  useEffect(() => {
    updateVmmId(selectedOptions?.vmmId?.toString() ?? '')
    updateSellerId(selectedOptions?.id ?? '')
  }, [selectedOptions, updateVmmId, updateSellerId])

  const onDatePickerUpdate = (_id: string, value: any) => {
    if (value.startDate && value.endDate) {
      updateTimeFrame(value)
    }
  }

  const handleFiltersUpdate = (
    updateType: 'Division' | 'Category',
    id: string,
    value: GreenFieldCategoryAutoCompleteOptionType,
  ) => {
    const isUpdateTypeCategory = updateType === 'Category'
    if (isUpdateTypeCategory) {
      onCategoryUpdate(id, value)
    } else onDivisionUpdate(id, value)

    logFireflyEvent(
      isUpdateTypeCategory
        ? FireflyEvent.RETURN_INSIGHTS_CATEGORY_SELECTION
        : FireflyEvent.RETURN_INSIGHTS_DIVISION_SELECTION,
      `${isUpdateTypeCategory ? 'category' : 'division'}-selection`,
      value?.label,
    )
  }
  const logFireflyEvent = (event: FireflyEvent, key?: string, data?: any) => {
    if (isExternalUser) {
      trackCustomEvent(event, key ?? event.toString(), data ?? '')
    }
  }

  return (
    <StyledFillterContainer className="hc-bg-grey07 hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading size={4}>Showing Details For :</Heading>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container>
            {isExternalView && (
              <Grid.Item>
                <HelpArticle />
              </Grid.Item>
            )}
            <Grid.Item>
              <Download />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        {!isExternalView && (
          <Grid.Item xs={3}>
            <label>
              <strong>Partner</strong>
            </label>
            {renderComponent({ placeholder: 'Select Partner' })}
          </Grid.Item>
        )}
        <Grid.Item xs={isExternalView ? 4 : 3}>
          <Autocomplete
            label="Group"
            placeholder="Select Group"
            options={categoryOptions}
            onUpdate={(id, value) => handleFiltersUpdate('Category', id, value)}
          />
        </Grid.Item>
        {!isExternalView && (
          <Grid.Item xs={3}>
            <Autocomplete
              label="Division"
              placeholder="Select Division"
              options={divisionOptions}
              onUpdate={(id, value) =>
                handleFiltersUpdate('Division', id, value)
              }
            />
          </Grid.Item>
        )}
        <Grid.Item xs={isExternalView ? 4 : 3}>
          <StyledDatePicker
            id="return-insights-date-picker"
            type="gregorian-range"
            label="Order Time Frame"
            onUpdate={onDatePickerUpdate}
            value={timeFrame}
            quickSelectRange={dateQuickSelectionRange}
            disableDates={{ after: compareDatePickerDisableDate }}
            hintText="Returns data cannot be accessed for recent 30 days"
          />
        </Grid.Item>
      </Grid.Container>
    </StyledFillterContainer>
  )
}

export default ReturnInsightsFilter
