import {
  Button,
  ButtonGroup,
  ButtonProps,
  Divider,
  Grid,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { StyledReturnRateCardContainer } from '../../ReturnInsights/styles'

import ReportDownload from '../ReportDownload'
import { useState } from 'react'
import UnshippedPastDueGraphByPartner from './UnshippedPastDueGraphByPartner'
import UnshippedPastDueGraphByAge from './UnshippedPastDueGraphByAge'

export enum UnshippedPastDueFilter {
  PARTNER,
  AGE,
}

const UnshippedPastDue = () => {
  const [selectedFilter, setSelectedFilter] = useState<UnshippedPastDueFilter>(
    UnshippedPastDueFilter.PARTNER,
  )

  const getBtnType = (type: UnshippedPastDueFilter): ButtonProps['type'] => {
    if (selectedFilter === type) return 'primary'
    return 'secondary'
  }
  return (
    <StyledReturnRateCardContainer className="hc-pa-normal">
      <Grid.Container justify="space-between" align="top">
        <Grid.Item className="hc-pl-md hc-pr-md">
          <Heading className="hc-fs-md">Unshipped Past Due</Heading>
          <p className="font-color-secondary hc-fs-xs">
            Count of order IDs with items crossing Requested Ship Date
          </p>
        </Grid.Item>

        <Grid.Item>
          <Grid.Container direction="row" align="top" justify="flex-end">
            <Grid.Item>
              <ButtonGroup>
                <Button
                  onClick={() =>
                    setSelectedFilter(UnshippedPastDueFilter.PARTNER)
                  }
                  className="hc-fs-xs"
                  type={getBtnType(UnshippedPastDueFilter.PARTNER)}
                >
                  Partners
                </Button>
                <Button
                  onClick={() => setSelectedFilter(UnshippedPastDueFilter.AGE)}
                  className="hc-fs-xs"
                  type={getBtnType(UnshippedPastDueFilter.AGE)}
                >
                  Age
                </Button>
              </ButtonGroup>
            </Grid.Item>

            <Grid.Item>
              <ReportDownload
                returnPerformanceReportTypes={['UNSHIPPED_ORDER_COUNT_RATE']}
                fileName="unshipped-order-count-rate"
                itemTypeIds={[]}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>

      <Divider className="hc-mt-xs" />

      <Grid.Item xs={12} className="hc-pa-none">
        <Grid.Container align="center" justify="center">
          {selectedFilter === UnshippedPastDueFilter.PARTNER ? (
            <UnshippedPastDueGraphByPartner />
          ) : (
            <UnshippedPastDueGraphByAge />
          )}
        </Grid.Container>
      </Grid.Item>
    </StyledReturnRateCardContainer>
  )
}

export default UnshippedPastDue
