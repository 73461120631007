import React, { ChangeEvent, useState, type SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseReferralFeeTable from './BaseReferralFeeTable'
import HeaderTitle from 'components/common/HeaderTitle'
import useTable from 'components/common/EnhancedTable/useTable'

import { getReferralBasePercentages } from 'services/referralBasePercentages'
import { Direction } from 'services/pageableHelper'

import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { CollectionResponse } from 'types/Response'
import Attribute, { ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import { TaxonomyTypeahead } from 'components/common/Typeahead'
import {
  Button,
  ButtonProps,
  Card,
  Grid,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import { isOneOfUserRoles } from 'services/authorization'
import { getMemberOf } from 'store/selectors'
import { EditReferralFeesDrawer } from 'components/ReferralFeesV2/EditReferralFeesDrawer'
import { DialogEnum } from 'components/common/Dialog'
import { openDialog } from 'store/dialog/actionCreator'
import { PriceRangePayload } from 'components/ReferralFeesV2/types'
import { StyledText } from './styles'

export enum ReferralTypeEnum {
  PRODUCT_SUBTYPE = 'PRODUCT_SUBTYPE',
  ITEM_TYPE = 'ITEM_TYPE',
}

interface ReferralType {
  name: ReferralTypeEnum
}

export const BaseReferralFeesPage = () => {
  const [pending, setPending] = React.useState(false)
  const [referralFees, setReferralFees] = React.useState<
    ReferralBasePercentage[]
  >([])
  const [total, setTotal] = React.useState(0)

  const [isEditFlyoutOpen, setIsEditFlyoutOpen] = useState(false)

  const memberOf = useSelector(getMemberOf)
  const reduxDispatch = useDispatch()

  const [subtypeId, setSubtypeId] = useState('')
  const [itemTypeId, setItemTypeId] = useState('')

  const [title, setTitle] = useState('')
  const [percent, setPercent] = useState<Nullable<string>>(null)
  const [attribute, setAttribute] = useState<Nullable<Attribute>>(null)

  const [priceRangeRows, setPriceRangeRows] = useState<PriceRangePayload[]>([])

  const [selectedTab, setSelectedTab] = useState(
    ReferralTypeEnum.PRODUCT_SUBTYPE,
  )

  const isSubtype = selectedTab === ReferralTypeEnum.PRODUCT_SUBTYPE

  const handleFormChange =
    ({ type }: { type: 'attribute' | 'percent' }) =>
    (value: Nullable<Attribute | Nullable<string>>) => {
      if (type === 'attribute') {
        setAttribute(value as Nullable<Attribute>)
      } else if (type === 'percent') {
        setPercent(value as Nullable<string>)
      }
    }

  const handleReferralFeeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPercent(e.target.value)
  }

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  React.useEffect(() => {
    if (!isEditFlyoutOpen) {
      setPending(true)
      getReferralBasePercentages(
        table.state,
        true,
        subtypeId,
        itemTypeId,
        selectedTab,
      ).then((res: CollectionResponse<ReferralBasePercentage>) => {
        setReferralFees(res.data)
        setTotal(res.total)
        setPending(false)
      })
    }
  }, [table.state, itemTypeId, subtypeId, isEditFlyoutOpen, selectedTab])

  const handleSubTypeChange = (value: any) => {
    if (!value) return
    setSubtypeId(value.id)
  }

  const handleItemTypeChange = (value: any) => {
    if (!value) return
    setItemTypeId(value.id)
  }

  const handleFilterClear = () => {
    setSubtypeId('')
    setItemTypeId('')
  }

  const handleOnEditClick = (
    title: string,
    rowItem?: ReferralBasePercentage,
  ) => {
    setIsEditFlyoutOpen(true)
    setTitle(title)
    if (rowItem) {
      setPercent((rowItem.referral_percentage * 100).toFixed())
      const attribute = {
        id: rowItem.sub_type_id ?? rowItem.item_type_id,
        name: rowItem.subtypeName ?? rowItem.itemTypeName,
      }
      setAttribute(attribute)
      if (rowItem.price_range_referral_percentages) {
        const priceRangeRowsCopy = rowItem.price_range_referral_percentages
        priceRangeRowsCopy.forEach((item) => {
          item.referral_percentage =
            item.referral_percentage &&
            (Number(item.referral_percentage) * 100).toFixed(2)
        })
        setPriceRangeRows(priceRangeRowsCopy)
      }
    }
  }

  const handleCloseEditFlyout = () => {
    setIsEditFlyoutOpen(false)
    setPercent('')
    setAttribute(null)
    setPriceRangeRows([])
  }

  const handleItemTypeViewHistory =
    (referralPercentage: ReferralBasePercentage) => () => {
      const { item_type_id, itemTypeName } = referralPercentage
      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.PRICE_RANGE_BASE_REFERRAL_FEE_HISTORY,
          componentProps: {
            itemTypeId: item_type_id,
            itemTypeName,
            isSubtype,
          },
        }),
      )
    }

  const handleViewHistory =
    (referralPercentage: ReferralBasePercentage) => () => {
      const { sub_type_id, subtypeName } = referralPercentage
      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.PRICE_RANGE_BASE_REFERRAL_FEE_HISTORY,

          componentProps: {
            subtypeId: sub_type_id,
            subtypeName,
            isSubtype,
          },
        }),
      )
    }

  const canEditRoles = [USER_ROLE_ADMIN, USER_ROLE_OPS]

  const canEdit = isOneOfUserRoles(memberOf, canEditRoles)

  const AddNewBaseFee = ({ children }: ButtonProps) => (
    <Button
      children={children}
      key="add-new-base-fee-button"
      data-testid="add-new-base-fee-button"
      type="primary"
      size="normal"
      variant="contained"
      onClick={() => handleOnEditClick?.('Add New Base Fee')}
    />
  )

  return (
    <>
      <HeaderTitle title="Base Referral Fees" />

      <Grid.Container>
        <Grid.Item xs={12}>
          <StyledText>
            Base referral fees apply to all partners. It can be configured by
            product subtype or item type level. Note that partner-specific rates
            override base rates if applied.
          </StyledText>
        </Grid.Item>

        <Grid.Container className="hc-pt-xl" justify="space-between">
          <Grid.Item xs={9} className="hc-pl-lg">
            <Card corners="none">
              <Tabs
                size="expanded"
                activeTab={selectedTab}
                onTabSelect={(_event: SyntheticEvent, tab: ReferralType) =>
                  setSelectedTab(tab.name)
                }
              >
                <Tabs.Item name={ReferralTypeEnum.PRODUCT_SUBTYPE}>
                  SUBTYPE
                </Tabs.Item>
                <Tabs.Item name={ReferralTypeEnum.ITEM_TYPE}>
                  ITEMTYPE
                </Tabs.Item>
              </Tabs>
            </Card>
          </Grid.Item>
          {canEdit && (
            <Grid.Item className="hc-pr-lg">
              <AddNewBaseFee>ADD NEW BASE FEE</AddNewBaseFee>
            </Grid.Item>
          )}
        </Grid.Container>
        <Grid.Container className="hc-pt-xl hc-pl-sm">
          <Grid.Item xs={4}>
            <TaxonomyTypeahead
              searchType={isSubtype ? ITEM_SUBTYPE : ITEM_TYPE}
              placeholder={`Search ${isSubtype ? 'Subtype' : 'Item Type'}`}
              onChange={isSubtype ? handleSubTypeChange : handleItemTypeChange}
              onClear={handleFilterClear}
            />
          </Grid.Item>
        </Grid.Container>

        <Grid.Item xs={12}>
          <BaseReferralFeeTable
            isSubtype={isSubtype}
            table={table}
            data={referralFees}
            total={total}
            isPending={pending}
            canEdit={canEdit}
            handleOnEditClick={handleOnEditClick}
            viewHistory={
              isSubtype ? handleViewHistory : handleItemTypeViewHistory
            }
          />
        </Grid.Item>
      </Grid.Container>
      {isEditFlyoutOpen && (
        <EditReferralFeesDrawer
          selectedType={selectedTab}
          attribute={attribute}
          percent={percent ?? ''}
          title={title}
          isBaseReferralFee={true}
          isEditFlyoutOpen={isEditFlyoutOpen}
          handleCloseEditFlyout={handleCloseEditFlyout}
          onRequestChange={handleFormChange}
          handleReferralFeeChange={handleReferralFeeChange}
          priceRanges={priceRangeRows}
          setSelectedTab={setSelectedTab}
        />
      )}
    </>
  )
}

export default BaseReferralFeesPage
