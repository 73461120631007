import { FC, useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import startCase from 'lodash/fp/startCase'
import capitalize from 'lodash/fp/capitalize'
import {
  Grid,
  Placeholder,
  Heading,
  Button,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

import HeaderTitle from 'components/common/HeaderTitle'
import { datePattern } from 'v2/constant/date'
import { MerchandiseHierarchy, PromotionDetails } from 'v2/services/promotions'
import { formatDiscountLabel } from 'v2/utils/helper'
import { getPSTDateAndTime } from 'v2/utils/date'
import promotionByIdQuery from 'v2/query/promotionByIdQuery'
import {
  StyledProdTypesContainer,
  StyledSellerPromotionsPage,
  StyledSubtypeButton,
} from './styles'
import { currentSellerId, isRoleExternalUserSelector } from 'store/selectors'
import PromotionParticipation from './PromotionParticipation'
import PromotionStatusChipOnDates from 'components/PromotionsPage/PromotionStatusChipOnDates'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import PromotionsHelpArticle from 'v2/pages/external/SellerPromotionDetailsPage/promotionHelpArticle'
import { AllowedProductSubType } from 'v2/types/promotions'
import ItemTypeModal from './ItemTypeModal'

const SellerPromotionDetailsPage: FC = () => {
  const { promotionId = '' } = useParams()
  const sellerId = useSelector(currentSellerId) ?? ''
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isModalVisibile, setModalVisibile] = useState(false)
  const [selectProductSubtype, setSelectedProductSubtype] =
    useState<AllowedProductSubType>()

  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const onRequestClose = () => setIsDrawerVisible(false)

  const {
    data: promotion,
    isFetching,
    isError,
  } = useQuery(
    ['SELLER_PROMOTION_DETAILS', { promotionId }],
    () =>
      promotionByIdQuery({
        promotionId,
        sellerId,
      }),
    { enabled: !!promotionId },
  )

  const handleButtonClick = (subType: any) => {
    setSelectedProductSubtype(subType)
    setModalVisibile(true)
  }

  const handleModalClose = () => {
    setModalVisibile(false)
  }

  const getDivisions = useMemo(
    () =>
      promotion?.merchandiseHierarchies
        ?.map(
          (merch: MerchandiseHierarchy) =>
            merch?.division_name &&
            startCase(merch?.division_name?.toLowerCase()),
        )
        .join(' '),
    [promotion],
  )

  const getPyramids = useMemo(
    () =>
      promotion?.merchandiseHierarchies
        ?.map(
          (merch: MerchandiseHierarchy) =>
            merch?.pyramid_name &&
            startCase(merch?.pyramid_name?.toLowerCase()),
        )
        .join(' '),
    [promotion],
  )

  useEffect(() => {
    if (isExternalUser && promotion) {
      trackCustomEvent(
        FireflyEvent.PROMOTIONS_SELLER_PAGE,
        'promotion-details',
        `promotion id: ${promotion?.promotionId}, promo description: ${
          promotion?.promotionDescription ?? 'N/A'
        }, divisions: ${getDivisions} pyramids: ${getPyramids}`,
        '',
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotion])

  if (isFetching) {
    return (
      <>
        <HeaderTitle title="Promotions > Promotions detail" />
        <Placeholder.Rect height="50px" />
        <Placeholder.Rect height="500px" />
      </>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>
          There was an error fetching the promotion details. Please try again in
          a few mins.
        </p>
      </div>
    )
  }

  return (
    <StyledSellerPromotionsPage>
      <HeaderTitle title="Promotions > Promotions detail" />
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading className="hc-mb-normal normal-weight">
            {promotion?.promotionDescription ?? promotion?.umbrellaLabel}
          </Heading>
        </Grid.Item>
        <Grid.Item className="hc-mb-normal">
          <Button type="secondary" onClick={() => setIsDrawerVisible(true)}>
            <strong>Help Articles</strong>
          </Button>
        </Grid.Item>
      </Grid.Container>

      <Grid.Container className="hc-ma-none hc-bg-grey06 border-all border-radius">
        <Grid.Item xs={12} className="hc-ma-none border-bottom">
          <Grid.Container>
            <Grid.Item className="hc-pa-min" xs={3}>
              <p className="hc-mb-none hc-pl-dense hc-pv-dense">
                <strong>Promo ID: </strong>
                {promotion?.promotionId}
              </p>
            </Grid.Item>
            <Grid.Item className="hc-pa-min" xs={3}>
              {promotion?.fiscalMonth && (
                <p className="hc-mb-none hc-pv-dense">
                  <strong>Fiscal Month: </strong>
                  {promotion?.fiscalMonth}
                </p>
              )}
            </Grid.Item>
            <Grid.Item className="hc-pa-min" xs={3}>
              {promotion?.fiscalWeek && (
                <p className="hc-mb-none hc-pv-dense">
                  <strong>Fiscal Week: </strong>
                  {promotion?.fiscalWeek}
                </p>
              )}
            </Grid.Item>

            <Grid.Item className="hc-pa-min" xs={3}>
              <strong className="hc-mr-dense">Promotion Status:</strong>
              <PromotionStatusChipOnDates
                startDate={promotion?.startDate as string}
                endDate={promotion?.endDate as string}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-ma-none">
          <Grid.Container>
            <Grid.Item className="hc-ma-none hc-pa-none border-right" xs={6}>
              <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
                <strong>Promo Description: </strong>
                {promotion?.promotionDescription ?? promotion?.umbrellaLabel}
              </p>
              <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
                <strong>Discount: </strong>
                {promotion?.details?.map((detail: PromotionDetails) => (
                  <>
                    {startCase(detail?.discount_type)}{' '}
                    {formatDiscountLabel(
                      detail?.discount_type,
                      detail?.discount_value,
                    )}
                  </>
                ))}
              </p>
              {promotion?.merchandiseHierarchies?.length !== 0 && (
                <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
                  <strong>Division: </strong>
                  {promotion?.merchandiseHierarchies?.map(
                    (merch: MerchandiseHierarchy) => (
                      <>
                        {merch?.division_name &&
                          startCase(merch?.division_name?.toLowerCase())}
                      </>
                    ),
                  )}
                </p>
              )}

              <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
                <strong>Start Date: </strong>
                <>
                  {promotion?.startDate &&
                    `${format(
                      getPSTDateAndTime(promotion?.startDate),
                      datePattern.verbose.MMMM_do_yyyy_time,
                    )} PST`}
                </>
              </p>
              <p className="hc-mb-none border-bottom hc-pl-normal hc-pv-dense">
                <strong>End Date: </strong>
                <>
                  {promotion?.endDate &&
                    `${format(
                      getPSTDateAndTime(promotion?.endDate),
                      datePattern.verbose.MMMM_do_yyyy_time,
                    )} PST`}
                </>
              </p>

              {promotion?.participationEndDate && (
                <p className="hc-mb-none hc-pl-normal hc-pv-dense">
                  <strong>Participation Deadline: </strong>
                  <>
                    {`${format(
                      getPSTDateAndTime(promotion?.participationEndDate),
                      datePattern.verbose.MMMM_do_yyyy_time,
                    )} PST`}
                  </>
                </p>
              )}
            </Grid.Item>
            <Grid.Item className="hc-pa-none" xs={6}>
              {promotion?.allowedProductSubTypes?.length !== 0 && (
                <>
                  <div className="hc-mb-none hc-ta-center hc-pv-dense border-bottom">
                    <strong>Allowed Subtypes </strong>
                    <Tooltip
                      content="Click on each subtype to view all allowed item-types with respective subtype."
                      location="bottom"
                    >
                      <EnterpriseIcon icon={InfoIcon} />
                    </Tooltip>
                  </div>
                  <StyledProdTypesContainer className="border-bottom">
                    {promotion?.allowedProductSubTypes?.map(
                      (allowed_product_sub_type: any) => (
                        <Grid.Item
                          className="hc-pa-min border-right border-bottom"
                          xs={6}
                        >
                          <StyledSubtypeButton
                            onClick={() =>
                              handleButtonClick(allowed_product_sub_type)
                            }
                            type="ghost"
                          >
                            {capitalize(
                              allowed_product_sub_type?.sub_type_name,
                            )}
                          </StyledSubtypeButton>
                        </Grid.Item>
                      ),
                    )}
                  </StyledProdTypesContainer>
                </>
              )}
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {promotion?.promotionNotes && (
          <Grid.Item xs={12} className="hc-pa-none">
            <p className="hc-ph-normal hc-pv-dense border-top">
              <strong>Promo Notes: </strong>
              <>{promotion?.promotionNotes}</>
            </p>
          </Grid.Item>
        )}
      </Grid.Container>
      <PromotionParticipation promotion={promotion} />
      <PromotionsHelpArticle
        isDrawerVisible={isDrawerVisible}
        onRequestClose={onRequestClose}
      ></PromotionsHelpArticle>
      <ItemTypeModal
        isVisibile={isModalVisibile}
        selectedProductSubtype={selectProductSubtype}
        onRequestClose={handleModalClose}
      />
    </StyledSellerPromotionsPage>
  )
}

export default SellerPromotionDetailsPage
