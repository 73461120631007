import styled from '@emotion/styled'
import { Drawer, Form } from '@enterprise-ui/canvas-ui-react'

export const StyledDrawer = styled(Drawer)`
  &.C-Drawer {
    width: 40%;
    overflow: scroll;
  }
`

export const StyledForm = styled(Form)`
  .C-Input {
    font-size: small;
  }

  .C-Button {
    width: 100px;
  }

  .time-input {
    width: 100%;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid var(--canvas-border-color--input);
  }
`

export const StyledFormField = styled(Form.Field)`
  .C-Input {
    padding-left: 40px;
  }
`
export const StyledCurrencyInput = styled.div`
  &.dollar,
  &.percentage {
    position: relative;
    display: inline;
  }

  &.dollar:before {
    content: '$';
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 1;
    border-right: 1px solid var(--canvas-border-color--input);
    padding: 4px 10px 4px 0px;
    width: 24px;
  }

  &.percentage:before {
    content: '%';
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 1;
    border-right: 1px solid var(--canvas-border-color--input);
    padding: 4px 10px 4px 0px;
    width: 24px;
  }
`

export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px dashed var(--canvas-border-color--droparea);
  border-radius: 8px;
  padding: 24px 10px 24px 10px;
`

export const StyledReportProgress = styled.div`
  barcolor: 'primary';
`
