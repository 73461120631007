import React from 'react'

import ConfirmationDialog from 'components/common/Dialog/ConfirmationDialog'
import EditSellerTaxId from 'components/BasicInfoPage/Dialogs/EditSellerTaxId'
import EditSellerLegalName from 'components/BasicInfoPage/Dialogs/EditSellerLegalName'
import EditSellerPrimaryAddress from 'components/BasicInfoPage/Dialogs/EditSellerPrimaryAddress'
import EditSellerStatus from 'components/BasicInfoPage/Dialogs/EditSellerStatus'
import EditBusinessStructure from 'components/BasicInfoPage/Dialogs/EditBusinessStructure'
import EditSource from 'components/BasicInfoPage/Dialogs/EditSource'
import EditApprovalSettingsDialog from 'components/ItemListingSettingsPage/dialogs/EditApprovalSettingsDialog'
import EditShipment from 'components/Orders/OrderDetails/Dialogs/EditShipment'
import EditShippingAddress from 'components/ShippingInfoPage/Dialogs/EditShippingAddress'
import AddDistributionCenter from 'components/ShippingInfoPage/Dialogs/AddDistributionCenter'
import EditDistributionName from 'components/ShippingInfoPage/Dialogs/EditDistributionName'
import EditAboutCompany from 'components/BasicInfoPage/Dialogs/EditAboutCompany'
import EditDisplayName from 'components/BasicInfoPage/Dialogs/EditDisplayName'
import EditBannerAndLogo from 'components/BasicInfoPage/Dialogs/EditBannerAndLogo'
import EditPrivacyPolicy from 'components/BasicInfoPage/Dialogs/EditPrivacyPolicy'
import EditShippingMethods from 'components/ShippingMethodsPage/dialogs/EditShippingMethods'
import CreateSellerContact from 'components/UserAccessPage/Dialogs/CreateSellerContact'
import EditCarrierInfo from 'components/ShippingInfoPage/Dialogs/EditCarrierInfo'
import EditUserInfo from 'components/UserProfilePage/Dialogs/EditUserInfo'
import EditHoursOfOperation from 'components/ShippingInfoPage/Dialogs/EditHoursOfOperation'
import EditDatesClosedDialog from 'components/ShippingInfoPage/Dialogs/EditDatesClosedDialog'
import DeactivateReturnPolicy from 'components/ReturnPoliciesPage/Dialogs/DeactivateReturnPolicy'
import EditReturnPolicy from 'components/ReturnPoliciesPage/Dialogs/EditReturnPolicy'
import EditReturnAddress from 'components/ReturnPoliciesPage/Dialogs/EditReturnAddress'
import FinancialReconciliationReport from 'components/ReportsPage/Dialogs/FinancialReconciliationReport'
import SalesMetricsReport from 'components/ReportsPage/Dialogs/SalesMetricsReport'
import AddProtectedBrand from 'components/ProtectedBrandsPage/Dialogs/AddProtectedBrand'
import AllowedItemSettingsDialog from 'components/ItemListingSettingsPage/dialogs/AllowedItemSettingsDialog'
import PublishedItemData from 'components/Items/ItemDetailsPage/LatestItemData/dialogs/PublishedItemData'
import DateRangeDialog from 'components/ReportsPage/Dialogs/DateRangeDialog'
import EditApiTokensDialog from 'components/ApiTokensPage/Dialogs/EditApiTokensDialog'
import EditErrorCode from 'components/ErrorCodesPage/Dialogs/EditErrorCode'
import ItemReviewDialog from 'components/ReviewQueuePage/Dialogs/ItemReviewDialog'
import BulkReprocessDialog from 'components/ReviewQueuePage/Dialogs/BulkReprocessDialog'
import AddPartner from 'components/Sellers/SellersPage/Dialogs/AddPartner'
import AddIntegrationDialog from 'components/SourcesPage/Dialogs/AddIntegrationDialog'
import EditIntegrationDialog from 'components/SourcesPage/Dialogs/EditIntegrationDialog'
import AddBrand from 'components/DataMappingPage/ManageBrands/AddBrandDialog'
import AddErrorCodesDialog from 'components/ReviewQueuePage/Dialogs/AddErrorCodesDialog'
import EditEligibleStates from 'components/ShippingInfoPage/Dialogs/EditEligibleStates'
import ItemTypeUpload from 'components/ReportsPage/Dialogs/ItemTypeUpload'
import OrderDefectMetrics from 'components/OrderDefectsPage/Dialogs/OrderDefectMetrics'
import AddNewCase from 'components/OpenCases/OpenCasesPage/Dialogs/AddNewCase'
import CaseCreated from 'components/OpenCases/OpenCasesPage/Dialogs/CaseCreated'
import EditInternalTaxSetup from 'components/BasicInfoPage/Dialogs/EditInternalTaxSetup'
import ErrorReturnsContact from 'components/UserAccessPage/Dialogs/ErrorReturnsContact'
import ItemTypeSearchDialog from 'components/ReportsPage/Dialogs/ItemTypeSearchDialog'
import DaysPastSLADialog from 'components/ReportsPage/Dialogs/DaysPastSLADialog'
import PriceInventoryDialog from 'components/ReportsPage/Dialogs/PriceInventoryDialog'
import ItemAttributesAndValuesDialog from 'components/ReportsPage/Dialogs/ItemAttributesAndValuesDialog'
import DateAndSellerReportDialogContainer from 'components/ReportsPage/Dialogs/DateAndSellerReportDialog'
import FileUploadDialog from 'components/ReportsPage/Dialogs/FileUploadDialog'
import EditGlobalQuotas from 'components/GlobalQuotasPage/Dialogs/EditGlobalQuotas'
import SuspendedSuccessDialog from 'components/BarcodeReleasePage/BarcodeRelease/Dialogs/SuspendedSuccessDialog'
import SuspendItemDialog from 'components/ReviewQueuePage/Dialogs/SuspendItemDialog'
import ConfirmBarcodeRelease from 'components/BarcodeReleasePage/BarcodeRelease/Dialogs/ConfirmBarcodeRelease'
import ReleaseSuccessDialog from 'components/BarcodeReleasePage/BarcodeRelease/Dialogs/ReleaseSuccessDialog'
import RejectReleaseBarcodes from 'components/BarcodeReleasePage/BarcodeChange/Dialogs/RejectReleaseBarcodes'
import ItemInsightDialog from 'components/ItemInsightsPage/Dialogs/ItemInsightDialog'
import OrderDefectRateReportDialog from 'components/ReportsPage/Dialogs/OrderDefectRateReportDialog'
import OrderDefectRateReportGenerate from 'components/OrderDefectsPage/Dialogs/OrderDefectRateReportGenerate'
import PartnerDiversitySurvey from 'components/BasicInfoPage/Dialogs/PartnerDiversitySurvey'
import EditCompanyType from 'components/BasicInfoPage/Dialogs/EditCompanyType'
import EditOtherMarketPlaces from 'components/BasicInfoPage/Dialogs/EditOtherMarketplaces'
import EditGuestContact from 'components/BasicInfoPage/Dialogs/EditGuestContact'
import FeedbackDialog from 'components/common/FeedbackDialog'
import SurveyDialog from 'components/common/SurveyDialog'
import ReportDownloadDialog from 'components/common/ReportDownloadDialog'
import PartnerContactReportDialog from 'components/ReportsPage/Dialogs/PartnerContactReportDialog'
import EditNotification from 'components/AlertManagementPage/Dialogs/EditNotification'
import EditDistributionTimeZone from 'components/ShippingInfoPage/Dialogs/EditDistributionTimeZone'
import PreviewNotification from 'components/AlertManagementPage/Dialogs/PreviewNotitifcation'
import MessageDetailsDialog from 'components/TimelinePage/Dialogs/MessageDetailsDialog'
import EditSourcingInfo from 'components/BasicInfoPage/Dialogs/EditSourcingInfo'
import EditSeasonalEvent from 'components/SeasonalEventsPage/Dialogs/EditSeasonalEvent'
import ViewSeasonalEvent from 'components/SeasonalEventsPage/Dialogs/ViewSeasonalEvent'
import AddFutureRatesDialog from 'components/ReturnShippingRatesPage/Dialogs/AddFutureRatesDialog'
import EditPartnerDiscountDialog from 'components/ReturnShippingRatesPage/Dialogs/EditPartnerDiscountDialog'
import ViewPartnerDiscountHistoryDialog from 'components/ReturnShippingRatesPage/Dialogs/ViewPartnerDiscountHistoryDialog'
import ReviewInformActAttributes from 'components/InformActVerificationPage/Dialogs/ReviewInformActAttributes'
import AttributeValues from 'components/AttributesAndValuesPage/Dialogs/AttributeValues'
import RequestAttributeValues from 'components/AttributesAndValuesPage/Dialogs/RequestAttributeValues'
import ValueRequestDetail from 'components/AttributesAndValuesPage/Dialogs/ValueRequestDetail'

import ReportErrorFeedbackDialog from 'components/ReportsPage/Dialogs/ReportErrorFeedbackDialog'
import InventoryAndPriceReportDialog from 'components/ReportsPage/Dialogs/InventoryAndPriceReportDialog'
import FileReturnDisputeDialog from 'components/ReturnDisputeCasesPage/Dialogs/FileReturnDisputeDialog'
import ExternalReturnDisputeDialog from 'components/ReturnDisputeCasesPage/Dialogs/ExternalReturnDisputeDialog'
import InternalReturnDisputeDialog from 'components/ReturnDisputeCasesPage/Dialogs/InternalReturnDisputeDialog'
import ApprovedReturnDisputeFinancialsDialog from 'components/ReportsPage/Dialogs/ApprovedReturnDisputeFinancialsDialog'
import NeedsReviewDialog from 'components/ReturnDisputeCasesPage/Dialogs/NeedsReviewDialog'
import EditPromotion from 'components/PromotionsPage/Dialogs/EditPromotion'
import ItemErrorsReportDialog from 'components/ReportsPage/Dialogs/ItemErrorsReportDialog'
import SavedSearchDialog from 'components/common/Dialog/SavedSearchDialog'
import ManageSavedSearchDialog from './ManageSavedSearchDialog'
import UpdateSavedSearchDialog from './UpdateSavedSearchDialog'
import DeleteSavedSearchDialog from './DeleteSavedSearchDialog'
import InactivityDialog from 'components/ReviewQueuePage/Dialogs/InactivityDialog'
import EditCarrierMarkup from 'components/ShippingMarkupPage/Dialogs/EditCarrierMarkup'
import ViewCarrierMarkupHistory from 'components/ShippingMarkupPage/Dialogs/ViewCarrierMarkupHistory'
import ViewPartnerDiscountHistory from 'components/ShippingMarkupPage/Dialogs/ViewPartnerDiscountHistory'
import AddPartnerDiscount from 'components/ShippingMarkupPage/Dialogs/AddPartnerDiscount'
import EditPromotionModal from 'components/PromotionsPage/Dialogs/EditPromotionModal'
import PriceRangeBaseFeeChangeHistory from 'components/BaseReferralFeesPage/Dialogs/PriceRangeBaseFeeChangeHistory'
import PriceRangeFeeChangeHistory from 'components/ReferralFeesPage/dialogs/PriceRangeFeeChangeHistory'
import EditDCContact from 'components/ShippingInfoPage/Dialogs/EditDCContact'

// map of dialog components
const dialogComponentMap = {
  CONFIRMATION_DIALOG: ConfirmationDialog,
  EDIT_TAX_ID: EditSellerTaxId,
  EDIT_LEGAL_NAME: EditSellerLegalName,
  EDIT_PRIMARY_ADDRESS: EditSellerPrimaryAddress,
  EDIT_SELLER_STATUS: EditSellerStatus,
  EDIT_DC_CONTACT: EditDCContact,
  EDIT_SHIPPING_ADDRESS: EditShippingAddress,
  ADD_DISTRIBUTION_CENTER: AddDistributionCenter,
  EDIT_DISTRIBUTION_NAME: EditDistributionName,
  EDIT_DISTRIBUTION_TIMEZONE: EditDistributionTimeZone,
  EDIT_DISPLAY_NAME: EditDisplayName,
  EDIT_BUSINESS_STRUCTURE: EditBusinessStructure,
  EDIT_SOURCE: EditSource,
  EDIT_LOGO_BANNER: EditBannerAndLogo,
  EDIT_ABOUT_COMPANY: EditAboutCompany,
  EDIT_PRIVACY_POLICY: EditPrivacyPolicy,
  CREATE_SELLER_CONTACT: CreateSellerContact,
  EDIT_CARRIER_INFO: EditCarrierInfo,
  EDIT_USER_INFO: EditUserInfo,
  EDIT_HOURS_OF_OPERATION: EditHoursOfOperation,
  EDIT_DATES_CLOSED: EditDatesClosedDialog,
  DEACTIVATE_RETURN_POLICY: DeactivateReturnPolicy,
  EDIT_RETURN_POLICY: EditReturnPolicy,
  EDIT_RETURN_ADDRESS: EditReturnAddress,
  FINANCIAL_RECONCILIATION_REPORT: FinancialReconciliationReport,
  SALES_METRICS_REPORT: SalesMetricsReport,
  SALES_TAX_REPORT: DateRangeDialog,
  PRICE_RANGE_BASE_REFERRAL_FEE_HISTORY: PriceRangeBaseFeeChangeHistory,
  ADD_PROTECTED_BRAND: AddProtectedBrand,
  EDIT_ALLOWED_ITEM_SETTINGS: AllowedItemSettingsDialog,
  PRICE_RANGE_REFERRAL_FEE_HISTORY: PriceRangeFeeChangeHistory,
  PUBLISHED_ITEM_DATA: PublishedItemData,
  EDIT_SHIPMENT: EditShipment,
  UNPAID_ORDERS_REPORT: DateRangeDialog,
  EDIT_SHIPPING_METHOD: EditShippingMethods,
  EDIT_API_TOKENS: EditApiTokensDialog,
  EDIT_APPROVAL_SETTINGS: EditApprovalSettingsDialog,
  EDIT_ERROR_CODES: EditErrorCode,
  ITEM_REVIEW: ItemReviewDialog,
  BULK_RE_ENRICH: BulkReprocessDialog,
  ADD_PARTNER: AddPartner,
  ADD_INTEGRATION: AddIntegrationDialog,
  EDIT_INTEGRATION: EditIntegrationDialog,
  ASSORTMENT_ANALYSIS_REPORT: FileUploadDialog,
  ADD_BRAND: AddBrand,
  ADD_ERROR_CODES: AddErrorCodesDialog,
  EDIT_ELIGIBLE_STATES: EditEligibleStates,
  ITEM_TYPE_UPLOAD_REPORT: ItemTypeUpload,
  PERFORMANCE_METRICS: OrderDefectMetrics,
  ADD_NEW_CASE: AddNewCase,
  CASE_CREATED: CaseCreated,
  EDIT_INTERNAL_TAX_SETUP: EditInternalTaxSetup,
  ERROR_RETURNS_CONTACT: ErrorReturnsContact,
  ORDERS_REPORT: DateRangeDialog,
  RETURN_ORDERS_REPORT: DateRangeDialog,
  DATE_AND_SELLER_REPORT_DIALOG: DateAndSellerReportDialogContainer,
  ITEM_TYPE_SEARCH_DIALOG: ItemTypeSearchDialog,
  DAYS_PAST_SLA: DaysPastSLADialog,
  PRICE_INVENTORY_REPORT: PriceInventoryDialog,
  ITEM_ATTRIBUTES_AND_VALUES: ItemAttributesAndValuesDialog,
  EDIT_GLOBAL_QUOTAS: EditGlobalQuotas,
  SUSPENDED_SUCCESS_DIALOG: SuspendedSuccessDialog,
  SUSPENDED_ITEM_DIALOG: SuspendItemDialog,
  CONFIRM_BARCODE_RELEASE: ConfirmBarcodeRelease,
  BARCODE_RELEASE_SUCCESS: ReleaseSuccessDialog,
  REJECT_RELEASE_BARCODE: RejectReleaseBarcodes,
  ITEM_INSIGHT_DIALOG: ItemInsightDialog,
  ORDER_DEFECT_RATE_REPORT_DIALOG: OrderDefectRateReportDialog,
  ORDER_DEFECT_RATE_GENERATE_DIALOG: OrderDefectRateReportGenerate,
  PARTNER_DIVERSITY_SURVEY: PartnerDiversitySurvey,
  EDIT_COMPANY_TYPE: EditCompanyType,
  EDIT_OTHER_MARKETPLACES: EditOtherMarketPlaces,
  EDIT_GUEST_CONTACT: EditGuestContact,
  FEEDBACK_DIALOG: FeedbackDialog,
  SURVEY_DIALOG: SurveyDialog,
  REPORT_DOWNLOAD_DIALOG: ReportDownloadDialog,
  PARTNER_CONTACT_REPORT_DIALOG: PartnerContactReportDialog,
  EDIT_NOTIFICATION: EditNotification,
  PREVIEW_NOTIFICATION: PreviewNotification,
  MESSAGE_DETAILS_DIALOG: MessageDetailsDialog,
  EDIT_SOURCING_INFO: EditSourcingInfo,
  EDIT_SEASONAL_EVENT: EditSeasonalEvent,
  VIEW_SEASONAL_EVENT: ViewSeasonalEvent,
  ADD_FUTURE_RATES: AddFutureRatesDialog,
  EDIT_PARTNER_DISCOUNT: EditPartnerDiscountDialog,
  VIEW_DISCOUNT_HISTORY: ViewPartnerDiscountHistoryDialog,
  REVIEW_INFORM_ACT_ATTRIBUTES: ReviewInformActAttributes,
  ATTRIBUTE_VALUES: AttributeValues,
  REQUEST_ATTRIBUTE_VALUES: RequestAttributeValues,
  VALUE_REQUEST_DETAIL: ValueRequestDetail,
  REPORT_ERROR_FEEDBACK_DIALOG: ReportErrorFeedbackDialog,
  IVENTORY_AND_PRICE_REPORT_DIALOG: InventoryAndPriceReportDialog,
  FILE_RETURN_DISPUTE_DIALOG: FileReturnDisputeDialog,
  NEEDS_REVIEW_DIALOG: NeedsReviewDialog,
  EXTERNAL_RETURN_DISPUTE_DIALOG: ExternalReturnDisputeDialog,
  INTERNAL_RETURN_DISPUTE_DIALOG: InternalReturnDisputeDialog,
  APPROVED_RETURN_DISPUTE_FINANCIALS_DIALOG:
    ApprovedReturnDisputeFinancialsDialog,
  EDIT_PROMOTION_DIALOG: EditPromotion,
  NEW_EDIT_PROMOTION_DIALOG: EditPromotionModal,
  ITEM_ERRORS_REPORT_DIALOG: ItemErrorsReportDialog,
  SAVED_SEARCH_DIALOG: SavedSearchDialog,
  MANAGE_SAVED_SEARCH_DIALOG: ManageSavedSearchDialog,
  UPDATE_SAVED_SEARCH_DIALOG: UpdateSavedSearchDialog,
  DELETE_SAVED_SEARCH_DIALOG: DeleteSavedSearchDialog,
  INACTIVITY_DIALOG: InactivityDialog,
  EDIT_CARRIER_MARKUP: EditCarrierMarkup,
  VIEW_CARRIER_MARKUP_HISTORY: ViewCarrierMarkupHistory,
  VIEW_PARTNER_DISCOUNT_HISTORY: ViewPartnerDiscountHistory,
  ADD_PARTNER_DISCOUNT: AddPartnerDiscount,
}

export enum DialogEnum {
  CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG',
  EDIT_TAX_ID = 'EDIT_TAX_ID',
  EDIT_LEGAL_NAME = 'EDIT_LEGAL_NAME',
  EDIT_PRIMARY_ADDRESS = 'EDIT_PRIMARY_ADDRESS',
  EDIT_SELLER_STATUS = 'EDIT_SELLER_STATUS',
  EDIT_BUSINESS_STRUCTURE = 'EDIT_BUSINESS_STRUCTURE',
  EDIT_SOURCE = 'EDIT_SOURCE',
  EDIT_DC_CONTACT = 'EDIT_DC_CONTACT',
  EDIT_SHIPPING_ADDRESS = 'EDIT_SHIPPING_ADDRESS',
  ADD_DISTRIBUTION_CENTER = 'ADD_DISTRIBUTION_CENTER',
  EDIT_DISTRIBUTION_NAME = 'EDIT_DISTRIBUTION_NAME',
  EDIT_DISTRIBUTION_TIMEZONE = 'EDIT_DISTRIBUTION_TIMEZONE',
  EDIT_DISPLAY_NAME = 'EDIT_DISPLAY_NAME',
  EDIT_LOGO_BANNER = 'EDIT_LOGO_BANNER',
  EDIT_ABOUT_COMPANY = 'EDIT_ABOUT_COMPANY',
  EDIT_PRIVACY_POLICY = 'EDIT_PRIVACY_POLICY',
  CREATE_SELLER_CONTACT = 'CREATE_SELLER_CONTACT',
  EDIT_CARRIER_INFO = 'EDIT_CARRIER_INFO',
  EDIT_USER_INFO = 'EDIT_USER_INFO',
  EDIT_HOURS_OF_OPERATION = 'EDIT_HOURS_OF_OPERATION',
  EDIT_DATES_CLOSED = 'EDIT_DATES_CLOSED',
  DEACTIVATE_RETURN_POLICY = 'DEACTIVATE_RETURN_POLICY',
  EDIT_RETURN_POLICY = 'EDIT_RETURN_POLICY',
  EDIT_RETURN_ADDRESS = 'EDIT_RETURN_ADDRESS',
  FINANCIAL_RECONCILIATION_REPORT = 'FINANCIAL_RECONCILIATION_REPORT',
  SALES_METRICS_REPORT = 'SALES_METRICS_REPORT',
  SALES_TAX_REPORT = 'SALES_TAX_REPORT',
  PRICE_RANGE_BASE_REFERRAL_FEE_HISTORY = 'PRICE_RANGE_BASE_REFERRAL_FEE_HISTORY',
  ADD_PROTECTED_BRAND = 'ADD_PROTECTED_BRAND',
  EDIT_ALLOWED_ITEM_SETTINGS = 'EDIT_ALLOWED_ITEM_SETTINGS',
  PRICE_RANGE_REFERRAL_FEE_HISTORY = 'PRICE_RANGE_REFERRAL_FEE_HISTORY',
  PUBLISHED_ITEM_DATA = 'PUBLISHED_ITEM_DATA',
  EDIT_SHIPMENT = 'EDIT_SHIPMENT',
  UNPAID_ORDERS_REPORT = 'UNPAID_ORDERS_REPORT',
  EDIT_SHIPPING_METHOD = 'EDIT_SHIPPING_METHOD',
  EDIT_API_TOKENS = 'EDIT_API_TOKENS',
  EDIT_APPROVAL_SETTINGS = 'EDIT_APPROVAL_SETTINGS',
  EDIT_ERROR_CODES = 'EDIT_ERROR_CODES',
  ITEM_REVIEW = 'ITEM_REVIEW',
  BULK_RE_ENRICH = 'BULK_RE_ENRICH',
  ADD_PARTNER = 'ADD_PARTNER',
  ADD_INTEGRATION = 'ADD_INTEGRATION',
  EDIT_INTEGRATION = 'EDIT_INTEGRATION',
  ASSORTMENT_ANALYSIS_REPORT = 'ASSORTMENT_ANALYSIS_REPORT',
  ADD_BRAND = 'ADD_BRAND',
  ADD_ERROR_CODES = 'ADD_ERROR_CODES',
  EDIT_ELIGIBLE_STATES = 'EDIT_ELIGIBLE_STATES',
  ITEM_TYPE_UPLOAD_REPORT = 'ITEM_TYPE_UPLOAD_REPORT',
  PERFORMANCE_METRICS = 'PERFORMANCE_METRICS',
  ADD_NEW_CASE = 'ADD_NEW_CASE',
  CASE_CREATED = 'CASE_CREATED',
  EDIT_INTERNAL_TAX_SETUP = 'EDIT_INTERNAL_TAX_SETUP',
  ERROR_RETURNS_CONTACT = 'ERROR_RETURNS_CONTACT',
  ORDERS_REPORT = 'ORDERS_REPORT',
  RETURN_ORDERS_REPORT = 'RETURN_ORDERS_REPORT',
  DATE_AND_SELLER_REPORT_DIALOG = 'DATE_AND_SELLER_REPORT_DIALOG',
  ITEM_TYPE_SEARCH_DIALOG = 'ITEM_TYPE_SEARCH_DIALOG',
  DAYS_PAST_SLA = 'DAYS_PAST_SLA',
  PRICE_INVENTORY_REPORT = 'PRICE_INVENTORY_REPORT',
  ITEM_ATTRIBUTES_AND_VALUES = 'ITEM_ATTRIBUTES_AND_VALUES',
  EDIT_GLOBAL_QUOTAS = 'EDIT_GLOBAL_QUOTAS',
  SUSPENDED_SUCCESS_DIALOG = 'SUSPENDED_SUCCESS_DIALOG',
  SUSPENDED_ITEM_DIALOG = 'SUSPENDED_ITEM_DIALOG',
  CONFIRM_BARCODE_RELEASE = 'CONFIRM_BARCODE_RELEASE',
  BARCODE_RELEASE_SUCCESS = 'BARCODE_RELEASE_SUCCESS',
  REJECT_RELEASE_BARCODE = 'REJECT_RELEASE_BARCODE',
  ITEM_INSIGHT_DIALOG = 'ITEM_INSIGHT_DIALOG',
  ORDER_DEFECT_RATE_REPORT_DIALOG = 'ORDER_DEFECT_RATE_REPORT_DIALOG',
  ORDER_DEFECT_RATE_GENERATE_DIALOG = 'ORDER_DEFECT_RATE_GENERATE_DIALOG',
  PARTNER_DIVERSITY_SURVEY = 'PARTNER_DIVERSITY_SURVEY',
  EDIT_COMPANY_TYPE = 'EDIT_COMPANY_TYPE',
  EDIT_OTHER_MARKETPLACES = 'EDIT_OTHER_MARKETPLACES',
  EDIT_GUEST_CONTACT = 'EDIT_GUEST_CONTACT',
  FEEDBACK_DIALOG = 'FEEDBACK_DIALOG',
  SURVEY_DIALOG = 'SURVEY_DIALOG',
  REPORT_DOWNLOAD_DIALOG = 'REPORT_DOWNLOAD_DIALOG',
  PARTNER_CONTACT_REPORT_DIALOG = 'PARTNER_CONTACT_REPORT_DIALOG',
  EDIT_NOTIFICATION = 'EDIT_NOTIFICATION',
  PREVIEW_NOTIFICATION = 'PREVIEW_NOTIFICATION',
  MESSAGE_DETAILS_DIALOG = 'MESSAGE_DETAILS_DIALOG',
  EDIT_SOURCING_INFO = 'EDIT_SOURCING_INFO',
  EDIT_SEASONAL_EVENT = 'EDIT_SEASONAL_EVENT',
  VIEW_SEASONAL_EVENT = 'VIEW_SEASONAL_EVENT',
  ADD_FUTURE_RATES = 'ADD_FUTURE_RATES',
  EDIT_PARTNER_DISCOUNT = 'EDIT_PARTNER_DISCOUNT',
  VIEW_DISCOUNT_HISTORY = 'VIEW_DISCOUNT_HISTORY',
  REVIEW_INFORM_ACT_ATTRIBUTES = 'REVIEW_INFORM_ACT_ATTRIBUTES',
  ATTRIBUTE_VALUES = 'ATTRIBUTE_VALUES',
  REQUEST_ATTRIBUTE_VALUES = 'REQUEST_ATTRIBUTE_VALUES',
  VALUE_REQUEST_DETAIL = 'VALUE_REQUEST_DETAIL',
  REPORT_ERROR_FEEDBACK_DIALOG = 'REPORT_ERROR_FEEDBACK_DIALOG',
  IVENTORY_AND_PRICE_REPORT_DIALOG = 'IVENTORY_AND_PRICE_REPORT_DIALOG',
  FILE_RETURN_DISPUTE_DIALOG = 'FILE_RETURN_DISPUTE_DIALOG',
  NEEDS_REVIEW_DIALOG = 'NEEDS_REVIEW_DIALOG',
  EXTERNAL_RETURN_DISPUTE_DIALOG = 'EXTERNAL_RETURN_DISPUTE_DIALOG',
  INTERNAL_RETURN_DISPUTE_DIALOG = 'INTERNAL_RETURN_DISPUTE_DIALOG',
  APPROVED_RETURN_DISPUTE_FINANCIALS_DIALOG = 'APPROVED_RETURN_DISPUTE_FINANCIALS_DIALOG',
  EDIT_PROMOTION_DIALOG = 'EDIT_PROMOTION_DIALOG',
  NEW_EDIT_PROMOTION_DIALOG = 'NEW_EDIT_PROMOTION_DIALOG',
  ITEM_ERRORS_REPORT_DIALOG = 'ITEM_ERRORS_REPORT_DIALOG',
  SAVED_SEARCH_DIALOG = 'SAVED_SEARCH_DIALOG',
  MANAGE_SAVED_SEARCH_DIALOG = 'MANAGE_SAVED_SEARCH_DIALOG',
  UPDATE_SAVED_SEARCH_DIALOG = 'UPDATE_SAVED_SEARCH_DIALOG',
  DELETE_SAVED_SEARCH_DIALOG = 'DELETE_SAVED_SEARCH_DIALOG',
  INACTIVITY_DIALOG = 'INACTIVITY_DIALOG',
  EDIT_CARRIER_MARKUP = 'EDIT_CARRIER_MARKUP',
  VIEW_CARRIER_MARKUP_HISTORY = 'VIEW_CARRIER_MARKUP_HISTORY',
  VIEW_PARTNER_DISCOUNT_HISTORY = 'VIEW_PARTNER_DISCOUNT_HISTORY',
  ADD_PARTNER_DISCOUNT = 'ADD_PARTNER_DISCOUNT',
}

export const getDialogComponent = (componentName: DialogEnum) => {
  if (!componentName) return

  return dialogComponentMap[componentName]
}

interface Props {
  isOpen: boolean
  componentName?: DialogEnum
  componentProps?: any
}

export const Dialog = (props: Props) => {
  const { isOpen, componentName, componentProps } = props

  const contentComponent: any = componentName
    ? getDialogComponent(componentName)
    : null

  if (!contentComponent) return null

  return React.createElement(contentComponent, { ...componentProps, isOpen })
}

export default Dialog
