import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

interface FlagConfigItem {
  name: string
  stage: boolean
  prod: boolean
}

export enum FlagName {
  ITEM_ERRORS = 'ITEM_ERRORS',
  BUY_SHIPPING_MARKUP = 'BUY_SHIPPING_MARKUP',
  PROMO_VISIBILITY = 'PROMO_VISIBILITY',
  TGID_CONSENT = 'TGID_CONSENT',
  DC_CONTACT = 'DC_CONTACT',
  RETURN_INSIGHT_DASHBOARD_INTERNAL = 'RETURN_INSIGHT_DASHBOARD_INTERNAL',
  RETURN_INSIGHT_DASHBOARD_EXTERNAL = 'RETURN_INSIGHT_DASHBOARD_EXTERNAL',
  ENABLE_TWO_DAY_TNT = 'ENABLE_TWO_DAY_TNT',
  ERROR_WARNING_RADIO_BUTTON = 'ERROR_WARNING_RADIO_BUTTON',
  SHIP_NODE_SEARCH = 'SHIP_NODE_SEARCH',
  PROMO_VISIBILITY_3P = 'PROMO_VISIBILITY_3P',
  ORDER_DASHBOARD_INTERNAL = 'ORDER_DASHBOARD_INTERNAL',
  ORDER_DASHBOARD_EXTERNAL = 'ORDER_DASHBOARD_EXTERNAL',
}

const flagConfig: FlagConfigItem[] = [
  {
    name: FlagName.ITEM_ERRORS,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.BUY_SHIPPING_MARKUP,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.PROMO_VISIBILITY,
    stage: true,
    prod: true,
  },
  { name: FlagName.TGID_CONSENT, stage: true, prod: false },
  {
    name: FlagName.DC_CONTACT,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.RETURN_INSIGHT_DASHBOARD_INTERNAL,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.RETURN_INSIGHT_DASHBOARD_EXTERNAL,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.SHIP_NODE_SEARCH,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.ENABLE_TWO_DAY_TNT,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.ERROR_WARNING_RADIO_BUTTON,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.PROMO_VISIBILITY_3P,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.ORDER_DASHBOARD_INTERNAL,
    stage: false,
    prod: false,
  },
  {
    name: FlagName.ORDER_DASHBOARD_EXTERNAL,
    stage: false,
    prod: false,
  },
]

export const flag = (flagName: string) => {
  const { stage, prod } = flagConfig.find(
    ({ name }) => name === flagName,
  ) as FlagConfigItem

  if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
    return prod
  }

  return stage
}
