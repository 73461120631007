import { Grid } from '@enterprise-ui/canvas-ui-react'
import ReportProgress from 'components/common/ReportCard/ReportProgress'
import { ReportStatus } from 'types/Report'
import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import { StyledReportProgress } from './styles'
import { PromotionsReport } from 'types/Promotion'

interface Props {
  report: PromotionsReport
}

export const GenerationBar = ({ report }: Props) => {
  return report.status === ReportStatus.PENDING ||
    report.status === ReportStatus.PROCESSING ? (
    <Grid.Container>
      <Grid.Item item xs={8} id={report.bulk_request_id}>
        <p data-testid="generating-text">Processing...</p>
        <StyledReportProgress>
          <ReportProgress />
        </StyledReportProgress>
      </Grid.Item>
    </Grid.Container>
  ) : (
    <p>{formatDate(report.last_modified, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}</p>
  )
}

export default GenerationBar
