import { useEffect, useState, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'
import { getPromotionById } from 'services/promotions'
import { getPromotionById as getSellerPromotionById } from 'v2/services/promotions'
import { UpdatedPromotion } from 'types/Promotion'
import Typeahead from '.'

interface Props {
  placeholder?: string
  value?: Nullable<UpdatedPromotion>
  onChange: (value: Nullable<UpdatedPromotion>) => void
  onClear?: () => void
  clearOnSelect?: boolean
  isDisabled?: boolean
  sellerId?: string
  label?: string
}

const PromotionTypeahead = ({
  value: valueFromProps,
  onChange,
  onClear,
  clearOnSelect,
  isDisabled,
  sellerId,
  placeholder,
  label,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<UpdatedPromotion>>(valueFromProps)
  const [options, setOptions] = useState<UpdatedPromotion[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 3 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    if (enteredValue && enteredValue.length >= 9) {
      setLoading(true)
      let data

      if (sellerId) {
        data = await getSellerPromotionById(
          {
            params: {
              promotion_id: enteredValue,
            },
          },
          sellerId,
        )
      } else {
        data = await getPromotionById(enteredValue)
      }
      setOptions(data)
      setLoading(false)
    }
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: UpdatedPromotion,
  ) => {
    if (!(value instanceof Array)) {
      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<UpdatedPromotion>),
      )
      onChange(value as Nullable<UpdatedPromotion>)
    }
  }

  const getOptionLabel = (promotion: UpdatedPromotion) => promotion.promotion_id

  return (
    <div data-testid="promotion-typeahead">
      <Typeahead
        aria-label="Promotion ID"
        label={label}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        isOptionEqualToValue={(
          option: UpdatedPromotion,
          value: Nullable<UpdatedPromotion>,
        ) => option.id === value?.id}
        disabled={!!isDisabled}
        inputValue={inputValue}
        value={selectedValue}
      />
    </div>
  )
}

export default PromotionTypeahead
